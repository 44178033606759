<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">락커 중계기 목록</div>
        <div class="description">락카 중계기를 관리합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>업체별</th>
                  <td>
                    <div class="inner_td">
                      <select class="w_250">
                        <option>업체를 선택하세요</option>
                        <option>마이즈</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>키워드 검색</th>
                  <td>
                    <div class="inner_td">
                      <select>
                        <option>전체</option>
                        <option>중계기명</option>
                        <option>시리얼넘버</option>
                        <option>IP주소</option>
                      </select>
                      <input
                        type="text"
                        class="pop_text"
                        placeholder="구역명을 입력하세요"
                      />
                      <button type="button">검색</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="42" unit="건"
                                 @resultCount="console.log"/>
            <button @click="router.push('/locker/relay/add')" type="button">중계기 등록</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 7%" />
                  <col style="width: 5%" />
                  <col />
                  <col style="width: 17%" />
                  <col style="width: 13%" />
                  <col style="width: 16%" />
                  <col style="width: 14%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input type="checkbox" />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">상호명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">중계기명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">시리얼넘버</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">IP주소</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>등록된 락커</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="o1">
                      <input type="checkbox" />
                    </td>
                    <td class="no_mobile">2</td>
                    <td class="seat o1 o_br">
                      <span class="only_mobile">상호명 : </span>
                      <span>주식회사마이즈</span>
                    </td>
                    <td class="detail_page_a o2 o_title">
                      <div class="center_td">
                        <span class="only_mobile">중계기명 : </span>
                        <router-link to="/locker/relay/view" class="center_td">
                          <p class="text_bold long_p">1F-A</p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                    <td class="seat o3">
                      <span class="only_mobile">시리얼넘버 : </span>
                      <span>0000165486</span>
                    </td>
                    <td class="seat o4r">
                      <span class="only_mobile">IP주소 : </span>
                      <span>106.135.222.548</span>
                    </td>
                    <td class="seat o_stat">
                      <button @click="router.push('/locker/admin/list')" type="button">락커보기</button>
                    </td>
                  </tr>
                </tbody>
                <tbody style="display: none">
                  <tr class="no_data">
                    <td colspan="8">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <a href="###">삭제</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a href="###"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a href="###" class="on">1</a>
              <a href="###">2</a>
              <a href="###">3</a>
              <a href="###"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a href="###"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";

const router = useRouter();
</script>

<style scoped></style>
