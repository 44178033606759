<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">알림설정</div>
        <div class="description">락커 기본제공 시간 및 알림을 설정합니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">업체선택</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>업체</th>
                  <td>
                    <div class="inner_td">
                      <select class="w_250">
                        <option>업체를 선택하세요</option>
                        <option selected>주식회사마이즈</option>
                      </select>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">일일락커 설정</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>기본제공시간</th>
                  <td>
                    <div class="inner_td">
                      <select class="w_80">
                        <option>0시간</option>
                        <option selected>1시간</option>
                        <option>2시간</option>
                      </select>
                      <select class="w_80">
                        <option>0분</option>
                        <option>1분</option>
                        <option>2분</option>
                        <option>3분</option>
                        <option>4분</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>최대연장시간</th>
                  <td>
                    <div class="inner_td">
                      <select class="w_80">
                        <option>0시간</option>
                        <option>1시간</option>
                        <option selected>2시간</option>
                      </select>
                      <select class="w_80">
                        <option>0분</option>
                        <option>1분</option>
                        <option>2분</option>
                        <option>3분</option>
                        <option>4분</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>만료알림설정</th>
                  <td>
                    <div class="inner_td column left">
                      <div class="inner_td">
                        이용 만료 전 알림을 발송할 시간을 선택해주세요.
                      </div>
                      <CheckTabs :tabs="dailyAlarmOptions" @tab-checked="checkDailyAlarmTabs" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>미반납알림설정</th>
                  <td>
                    <div class="inner_td">
                      <input type="time" /> *반납 시까지 매일 설정한 시각에 맞춰
                      사용자에게 push 알림이 발송됩니다.
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">개인락커 설정</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>알림발송시각</th>
                  <td>
                    <div class="inner_td">
                      <input type="time" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>만료알림설정</th>
                  <td>
                    <div class="inner_td column left">
                      <div class="inner_td">
                        이용 만료 전 알림을 발송할 시간을 선택해주세요.
                      </div>
                      <CheckTabs :tabs="personalAlarmOptions"
                                 @tab-checked="checkPersonalAlarmTabs" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>미반납알림설정</th>
                  <td>
                    <div class="inner_td">
                      <input type="time" /> *반납 시까지 매일 설정한 시각에 맞춰
                      사용자에게 push 알림이 발송됩니다.
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a @click.prevent="saveAlarmSetting" href="###" class="on">설정저장</a>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import CheckTabs from "@/components/common/CheckTabs.vue";

const router = useRouter();


const dailyAlarmOptions = [
  {label:'1분전',value:1,on:false},
  {label:'3분전',value:3,on:false},
  {label:'5분전',value:5,on:false},
  {label:'10분전',value:10,on:false},
  {label:'20분전',value:20,on:false},
  {label:'30분전',value:30,on:false},
];

const personalAlarmOptions = [
  {label:'1일전',value:1,on:false},
  {label:'2일전',value:2,on:false},
  {label:'3일전',value:3,on:false},
  {label:'5일전',value:5,on:false},
  {label:'7일전',value:7,on:false},
  {label:'30일전',value:30,on:false},
];

function checkDailyAlarmTabs(value){
  dailyAlarmOptions.filter(i=>i.value === value)
      .map(i=>i.on = !i.on);
  console.log(dailyAlarmOptions)
}

function checkPersonalAlarmTabs(value){
  personalAlarmOptions.filter(i=>i.value === value)
      .map(i=>i.on = !i.on);
  console.log(personalAlarmOptions)
}

function saveAlarmSetting(){
  if(!alert('저장되었습니다.')){
    router.go(0)
  }
}
</script>

<style scoped></style>
