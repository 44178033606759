<template>
  <div class="part_title">기본정보</div>
  <table>
    <colgroup>
      <col style="width: 18%" class="col_width26"/>
      <col/>
    </colgroup>
    <tr>
      <th>상호명<span class="text_red">*</span></th>
      <td>
        <div class="inner_td">
          <input
              type="text"
              class="w_250"
              placeholder="상호명을 입력하세요"
              :value="companyName"
              @input="changeCompanyName"
          />
          <div class="validation" :class="valid.validCompanyName && companyName?'text_green':'text_red'">
            {{ companyNameMsg }}
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <th>대표자명<span class="text_red">*</span></th>
      <td>
        <input
            type="text"
            class="w_250"
            placeholder="대표자명을 입력하세요"
            v-model="ceoName"
        />
      </td>
    </tr>
    <tr>
      <th>사업자등록번호<span class="text_red">*</span></th>
      <td>
        <div class="inner_td b_number">
          <input type="text" class="w_55" maxlength="3" v-model="companyRegCode[0]"/>
          -
          <input type="text" class="w_55" maxlength="2" v-model="companyRegCode[1]"/>
          -
          <input type="text" class="w_55" maxlength="5" v-model="companyRegCode[2]"/>
          <div class="validation" :class="valid.validCompanyRegCode ?'text_green':'text_red'">
            {{ companyRegCodeMsg }}
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <th>대표번호<span class="text_red">*</span></th>
      <td>
        <div class="inner_td p_number">
          <input type="text" class="w_55" maxlength="4" v-model="phoneNumber[0]"/>
          -
          <input type="text" class="w_55" maxlength="4" v-model="phoneNumber[1]"/>
          -
          <input type="text" class="w_55" maxlength="4" v-model="phoneNumber[2]"/>
        </div>
      </td>
    </tr>
    <AddressInfo/>
  </table>
</template>

<script setup>
import AddressInfo from "@/components/company/AddressInfo.vue";
import {onMounted, ref, watch} from "vue";
import {useCompanyStore} from "@/store/company";
import {storeToRefs} from "pinia";
import {validateBusinessNum} from "@/util/regex";
import {debounce} from "lodash";
import {formatWithHyphen} from "@/util/formatUtils";

const companyStore = useCompanyStore();
const {isEdit} = defineProps(['isEdit']);

const {companyName, ceoName, companyRegCode, phoneNumber, valid} = storeToRefs(companyStore);
const companyNameMsg = ref('');
const companyRegCodeMsg = ref('');

const initial = {
  companyName: companyName.value,
  companyRegCode:[...companyRegCode.value],
};


watch(companyRegCode,
    async () => {
      if(isEdit && formatWithHyphen(initial.companyRegCode) === formatWithHyphen(companyRegCode.value)){
        companyStore.valid.validCompanyRegCode = true;
        companyRegCodeMsg.value = '';
        return;
      }
      if (!validateBusinessNum(companyRegCode.value)) {
        companyRegCodeMsg.value = '사업자등록 번호를 모두 입력해주세요'
        companyStore.valid.validCompanyRegCode = false;
      } else if (!await companyStore.isValidCompanyRegCode()) {
        companyRegCodeMsg.value = '이미 등록된 사업자등록 번호입니다'
      } else {
        companyRegCodeMsg.value = '등록가능한 사업자등록 번호입니다.'
      }
    },
    {deep: true}
);

function changeCompanyName(e) {
  const newCompanyName = e.target.value;
  companyName.value = newCompanyName;
  checkCompanyNameDebounced(newCompanyName);
}

const checkCompanyNameDebounced = debounce(async (newCompanyName) => {
  if (isEdit && newCompanyName === initial.companyName) {
    companyNameMsg.value = '';
    companyStore.valid.validCompanyName = true;
    return;
  }
  if (!newCompanyName) {
    companyNameMsg.value = '상호명을 입력해주세요.'
    companyStore.valid.validCompanyName = false;
  } else if (!await companyStore.isValidCompanyName(newCompanyName)) {
    companyNameMsg.value = '이미 등록된 상호명입니다.';
  } else { // api로 중복검사도 하기
    companyNameMsg.value = '등록가능한 상호명입니다.';
  }
}, 300);

</script>

<style scoped>

</style>