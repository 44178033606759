<template>
  <div class="inner_td">
    <button v-for="tab in tabs" :key="tab.value"
            type="button" :class="{on : checkedTabs.includes(tab.value)}"
            @click="clickTab(tab.value)"
    >
      {{ tab.label }}
    </button>
  </div>
</template>

<script setup>

import {ref} from "vue";

const emit = defineEmits(['tab-checked']);

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  defaultTab: {
    type: String,
    default: ''
  }
});

const checkedTabs = ref( props.tabs.filter(i=>i.on).map(i=>i.value));

function clickTab(tabValue){
  const index = checkedTabs.value.indexOf(tabValue);
  if(index !== -1){
    checkedTabs.value.splice(index,1);
  }else{
    checkedTabs.value.push(tabValue);
  }
  emit('tab-checked',tabValue);
}

</script>

<style scoped>

</style>