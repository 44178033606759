

export const companyListSortFields =[
    {label: '번호'}, {label: '상호명', key: 'companyName', direction: null},
    {label: '관리자계정', key: 'loginId', direction: null},
    {label: '생성일', key: 'createdAt', direction: null}, {label: '활성화 여부'},
];
export const memberListSortFields = [
    {label: '번호'},
    {label: '상호명', key: 'companyName', direction: null, super:true},
    {label: '이름', key: 'storeUserName', direction: null},
    {label: '휴대폰번호', key: 'storeUserPhoneNumber', direction: null},
    {label: '락커번호'}, {label: '배정락커 수'},
    {label: '배정이력'}, {label: '상태'},
];

export const memberApprovalListSortFields = [
    {label: '번호'},
    {label: '상호명', key: 'companyName', direction: null, super:true},
    {label: '이름', key: 'storeUserName', direction: null},
    {label: '휴대폰번호', key: 'storeUserPhoneNumber', direction: null},
    {label: '요청일시',key: 'requestedAt', direction: null},
    {label: '처리일시',key: 'processedAt', direction: null},
    {label: '상태'},
    {label: '관리'},
];

export const lockerListSortFields = [
    {label: '번호'},
    {label: '상호명',super: true},
    {label: '락커번호'},
    {label: '시리얼넘버'},
    {label: 'PIN번호'},
    {label: '배터리상태'},
    {label: '락커제어'},
    {label: '활성화여부'},
];

export const lockerUsageStatusListSortFields=[
    {label: '상호명', key: 'companyName', direction: null,super: true},
    {label: '락커번호'},
    {label: '시작일시',key: 'usageStartTime', direction: null},
    {label: '만료일시',key: 'usageEndTime', direction: null},
    {label: '유형'},
    {label: '이름',key: 'storeUserName', direction: null},
    {label: '사용상태'},
    {label: '관리'},
];

export const areaListSortFields = [
    {label: '번호'},
    {label: '상호명', key: 'companyName', direction: null, super: true},
    {label: '구역명', key: 'areaName', direction: null},
    {label: '등록된 락커'},
];

export const lockerBatchFields = [
    {label: '번호'},
    {label: '락커번호'},
    {label: '시리얼넘버'},
    {label: 'PIN번호'},
    {label: '중계기'},
    {label: '상태'},
    {label: '삭제'},
];
