<template>
    <div class="part_title">기본정보</div>
    <table>
        <colgroup>
        <col style="width: 18%" class="col_width26" />
        <col />
        </colgroup>
        <tbody>
        <tr v-if="authStore.isSuperAdmin">
            <th>업체선택<span class="text_red">*</span></th>
            <td>
            <div class="inner_td">
                <CompanySelector :isViewMode="false" v-model:companyId="companyId" @change="getAreaList"/>
                <!-- <select class="w_250">
                <option>업체를 선택하세요</option>
                <option selected>마이즈</option>
                </select> -->
            </div>
            </td>
        </tr>
        <tr>
            <th>구역<span class="text_red">*</span></th>
            <td>
            <div class="inner_td">
                <select class="w_250" v-model="areaId"
                        @change="getTabletList"
                        :disabled="companyId===null">
                    <option disabled :value="null">구역을 선택하세요</option>
                    <option v-for="area in areas" :value="area.areaId">{{ area.areaName }}</option>
                </select>
                <select class="w_250" v-model="tabletId"
                        :disabled="companyId===null || areaId === null">
                    <option disabled :value="null">중계기를 선택하세요</option>
                    <option v-for="tablet in tablets" :value="tablet.tabletId">{{ tablet.tabletName }}</option>
                </select>
                <!-- <select class="w_250">
                <option>구역을 선택하세요</option>
                <option selected>1F</option>
                </select>
                <select class="w_250">
                <option>중계기를 선택하세요</option>
                <option selected>1F 1~30</option>
                </select> -->
            </div>
            </td>
        </tr>
        <tr>
            <th>락커번호<span class="text_red">*</span></th>
            <td>
            <div class="inner_td">
                <input
                    type="text"
                    class="w_250"
                    placeholder="락커번호를 입력하세요"
                    v-model="lockerNum"
                />
                <div class="validation"
                     :class="lockerNumbValid.valid?'text_green':'text_red'">
                    {{ lockerNumbValid.msg }}
                </div>
                <!-- <div class="validation text_red">
                이미 등록된 락커번호입니다.
                </div> -->
            </div>
            </td>
        </tr>
        <tr>
            <th>시리얼넘버<span class="text_red">*</span></th>
            <td>
            <div class="inner_td">
                <input
                type="text"
                class="w_250"
                placeholder="시리얼넘버를 입력하세요"
                v-model="serialNumber"
                />
                <div class="validation"
                     :class="serialNumValid.valid?'text_green':'text_red'">
                    {{ serialNumValid.msg }}
                </div>
                <!-- <div class="validation text_red">
                이미 등록된 시리얼넘버입니다.
                </div> -->
            </div>
            </td>
        </tr>
        <tr>
            <th>PIN번호<span class="text_red">*</span></th>
            <td>
            <input
                type="text"
                class="w_250"
                placeholder="PIN번호를 입력하세요"
                v-model="pinNumber"
            />
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script setup>
import {onBeforeRouteLeave, useRouter} from "vue-router";
import {useAuthStore} from "@/store/auth";
import {onMounted, ref, watch} from "vue";
import CompanySelector from "@/components/common/CompanySelector.vue";
import {fetchAreaList} from "@/api/area";
import {fetchTabletList} from "@/api/tablet";
import {debounce} from "lodash";
import {useLockerStore} from "@/store/locker";
import {storeToRefs} from "pinia";
import {checkLockerNumber, checkSerialNumber, registerLocker} from "@/api/locker/locker";

const lockerStore = useLockerStore();
const authStore = useAuthStore();

const {companyId, areaId, tabletId, lockerNum, serialNumber, pinNumber, lockerNumbValid, serialNumValid} = storeToRefs(lockerStore);

const areas = ref([]);
const tablets = ref([]);

const originLockerNum = ref(lockerNum.value);
const originSerialNumber = ref(serialNumber.value);

// const lockerNumbValid = ref({
//   msg: '',
//   valid: false,
// });
// const serialNumValid = ref({
//   msg: '',
//   valid: false,
// });

onMounted(() => {
    if (!authStore.isSuperAdmin) {
    companyId.value = authStore.companyId;
    }
    getAreaList();
    getTabletList();

    debouncedCheckLockerNumber(lockerNum);
    debouncedCheckSerialNumber(serialNumber);
});

// onBeforeRouteLeave((to, from, next) => {
//   if (isStateChanged()) {
//     if (!confirm('입력 중인 내용이 사라집니다.\n계속 하시겠습니까?')) return;
//   }
//   next();
// });

watch(lockerNum, (newNum) => {debouncedCheckLockerNumber(newNum);});

const debouncedCheckLockerNumber = debounce(async (newNum) => {
    console.log("debouncedCheckLockerNumber called with", newNum);
  if (!newNum) {
    lockerNumbValid.value.valid = false;
    lockerNumbValid.value.msg = '락커번호를 입력해주세요';
    return;
  }
  
  if (!areaId.value) {
    alert('구역을 먼저 선택해주세요');
    lockerNum.value = null;
    return;
  }

  if (originLockerNum.value == newNum){ // 기존 값
    lockerNumbValid.value.valid = true;
    lockerNumbValid.value.msg = '';
    return;
  }

  try {
    const {data} = await checkLockerNumber(areaId.value, newNum);
    if (data.exists) {
      lockerNumbValid.value.valid = false;
      lockerNumbValid.value.msg = '이미 등록된 락커번호입니다.';
    } else {
      lockerNumbValid.value.valid = true;
      lockerNumbValid.value.msg = '등록 가능한 락커번호입니다.';
    }
  } catch (e) {
    lockerNumbValid.value.valid = false;
    console.error(e);
  }
},300);

watch(serialNumber, (newNum) => {debouncedCheckSerialNumber(newNum);});

const debouncedCheckSerialNumber = debounce(async (newNum) => {
    console.log("debouncedCheckSerialNumber called with", newNum);
  if (!newNum) {
    serialNumValid.value.valid = false;
    serialNumValid.value.msg = '시리얼넘버를 입력해주세요';
    return;
  }

  if (originSerialNumber.value == newNum.value || originSerialNumber.value == newNum){ // 기존 값
    serialNumValid.value.valid = true;
    serialNumValid.value.msg = '';
    return;
  }

  try {
      const {data} = await checkSerialNumber(newNum);
      if (data.exists) {
        serialNumValid.value.valid = false;
        serialNumValid.value.msg = '이미 등록된 시리얼넘버입니다.';
      } else {
        serialNumValid.value.valid = true;
        serialNumValid.value.msg = '등록 가능한 시리얼넘버입니다.';
      }
  } catch (e) {
    serialNumValid.value.valid = false;
    console.error(e);
  }
},300)

async function getAreaList() {
  if (companyId.value === null) return;
  try {
    const {data, status} = await fetchAreaList(companyId.value);
    if (status === 200) {
      areas.value = data;
    }
  } catch (e) {
    console.error(e);
  }
}

async function getTabletList() {
  if (areaId.value === null) return;
  try {
    const {data, status} = await fetchTabletList(areaId.value);
    if (status === 200) {
      tablets.value = data;
      debouncedCheckLockerNumber(lockerNum.value);
    }
  } catch (e) {
    console.error(e);
  }
}

</script>