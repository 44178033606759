// 문자배열을 하이픈(-)과 함께 이어주는 함수
export function formatWithHyphen(numberParts,sep='') {
    return numberParts.filter(item => item).join(sep);
}

// 핸드폰번호 문자열을 '-'기준으로 배열로 분리하는 함수
// 비어있는 값일 경우 빈 배열로
export function splitByHyphen(formattedNumbers) {
    if(!formattedNumbers.includes('-')){
        formattedNumbers = toHyphenPhoneNumber(formattedNumbers);
    }
    return formattedNumbers === '' ? ['', '', ''] : formattedNumbers.split('-');
}

export function formatDate(dateStr) {
    if(!dateStr) return null;
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function formatDateTime(dateStr, sep = '-',timeZone='') {
    if (!dateStr) return null;
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${year}${sep}${month}${sep}${day} ${hours}:${minutes}${timeZone ===''?'':`:00${timeZone}`}`;
}

export function timeToString(time){
    return time?.substring(0,16).replaceAll('T',' ');
}

// 핸드폰번호 하이픈 추가하기
export function toHyphenPhoneNumber(pNum){
    return pNum.substring(0,3) + '-'+ pNum.substring(3,7)+ '-'+pNum.substring(7);
}
// let b = p.substring(0,3) + '-'+ p.substring(3,7)+ '-'+p.substring(7);
