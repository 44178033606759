<template>
  <tr>
    <td class="o1">
      <input type="checkbox" v-model="usageInfo.isSelected"/>
    </td>
    <td class="seat o1 o_br" v-if="authStore.isSuperAdmin">
      <span class="only_mobile">상호명 : </span>
      <span>{{usageInfo.companyName}}</span>
    </td>
    <td class="detail_page_a o2 o_title">
      <div class="center_td">
        <p class="text_bold long_p">[{{usageInfo.areaName}}] {{usageInfo.lockerNum}}</p>
      </div>
    </td>
    <td class="seat o5 o_br" :class="{num:usageInfo.usageStartTime}">
      <span class="only_mobile">시작일시 : </span>
      <span class="text_bold">{{timeToString(usageInfo.usageStartTime,'.')||'-'}}</span>
    </td>
    <td class="seat o5 o_br" :class="{num:usageInfo.usageEndTime}">
      <span class="only_mobile">만료일시 : </span>
      <span>{{timeToString(usageInfo.usageEndTime,'.')||'-'}}</span>
    </td>
    <td class="seat o4r">
      <span class="only_mobile">유형 : </span>
      <span>{{lockerType}}</span>
    </td>
    <td class="seat o7 detail_page_a">
      <span class="only_mobile">이름 : </span>
      <span class="center_td">
        <a href="###" class="center_td">
          <p class="text_bold long_p">{{usageInfo.storeUserName||'-'}}</p>
          <img v-if="usageInfo.storeUserName" src="@/images/detail_page.png"/>
        </a>
      </span>
      <div class="num o8r">
        <span>{{usageInfo.phoneNumber?`(${usageInfo.phoneNumber})`:''}}</span>
      </div>
    </td>
    <td class="o3">
      <span class="only_mobile">사용상태 : </span>
      <span class="bold" :class="usageStatus.class">{{usageStatus.text}}</span>
      <template v-if="!isAvailable">
        <br/>
        <span class="text_grey">{{usageStatus.timeRemaining}}</span>
      </template>
    </td>
    <td class="o_stat">
      <div class="center_td inner_td">
        <button @click="onAssigmentModal" type="button" v-if="isAvailable">락커배정</button>
        <template v-else>
          <button @click="onExtensionModal" type="button">연장</button>
          <button type="button">회수</button>
        </template>
      </div>
    </td>
  </tr>
</template>

<script setup>

import {useAuthStore} from "@/store/auth";
import {computed} from "vue";
import {formatDateTime, timeToString} from "@/util/formatUtils";

const authStore = useAuthStore();

const emits = defineEmits(['onExtensionModal','onAssigmentModal']);

const props = defineProps({
  usageInfo:{
    type:Object,
    required:true
  }
});

const isAvailable = computed(()=>props.usageInfo.usageStatus ==='available');

const lockerType = computed(()=>{
  switch (props.usageInfo.usageType){
    case 'personal':
      return '개인락커';
    case 'daily':
      return '일일락커';
    default:
      return '-';
  }
});

// TODO 초과시간 계산 백엔드 수정 결과에 따라 다시 수정하기
// TODO 사용중이지만 만료일시가 지난 건은 기간만료로 처리하기
const usageStatus = computed(()=>{
  switch (props.usageInfo.usageStatus) {
    case 'inUse':
      return {
        text:'사용중',
        class:'text_green',
        timeRemaining : `잔여 ${calculateRemainingDate(props.usageInfo.remainingOrExceededTime)}일`
      };
    case 'expired':
      return {
        text:'기간만료',
        class:'text_red',
        timeRemaining: calculateReamainingTime(props.usageInfo.usageEndTime,
            props.usageInfo.remainingOrExceededTime),
      };
    default:
      return {text:'사용가능', class:'text_yellow',};}
});


function calculateRemainingDate(minute){
  return Math.floor(minute / 1440);
}
function calculateReamainingTime(referenceTime, timeRemaining) {
  const minutesInADay = 1440;
  const referenceDateTime = new Date(referenceTime.replace('Z',''));

  // 기준 시간으로부터 경과된 시간을 추가한 결과 시간 계산
  const elapsedDate = new Date(referenceDateTime.getTime() + timeRemaining * 60000);

  // 경과 시간이 하루 미만일 때 시, 분으로 표시
  if (timeRemaining < minutesInADay) {
    const hours = elapsedDate.getHours();
    const minutes = elapsedDate.getMinutes();

    // return `${hours}:${minutes.toString().padStart(2,'0')} 초과`;
    return `${Math.floor(timeRemaining/60)}:${minutes.toString().padStart(2,'0')} 초과`;
  } else {
    // 하루 이상일 경우 일 수로 표시
    const days = calculateRemainingDate(timeRemaining);
    return `${days}일 초과`;
  }
}

function onAssigmentModal(){
  emits('onAssigmentModal',{
    lockerId:props.usageInfo.lockerId,
    lockerNum:props.usageInfo.lockerNum,
    areaName: props.usageInfo.areaName,
    companyId: props.usageInfo.companyId,
  });
}
function onExtensionModal(){
  emits('onExtensionModal',props.usageInfo);
}

</script>

<style scoped>

</style>