<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">락커 구역 상세보기</div>
        <div class="description">모든 항목은 필수입력 항목입니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>업체선택<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <CompanySelector :isViewMode="false" v-model:companyId="companyId"/>
                      <!-- <select class="w_250">
                        <option>업체를 선택하세요</option>
                        <option selected>마이즈</option>
                      </select> -->
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>구역명<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        class="w_250"
                        placeholder="구역명을 입력하세요"
                        :value="areaName"
                        @input="inputAreaName"
                      />
                      <div class="validation"
                        :class="areaNameValid.valid?'text_green':'text_red'">
                        {{ areaNameValid.msg }}
                      </div>
                      <!-- <div class="validation text_red">
                        이미 등록된 구역명입니다.
                      </div> -->
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a @click.prevent="editLockerArea" href="###" class="on">저장</a>
            <a @click.prevent="deleteLockerArea" href="###">삭제</a>
            <a @click.prevent="listLocker" href="###">목록</a>
            <!-- <router-link to="/locker/area/list">목록</router-link> -->
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onBeforeRouteLeave, useRoute, useRouter} from "vue-router";
import {useAuthStore} from "@/store/auth";
import {onMounted, ref, watch} from "vue";
import CompanySelector from "@/components/common/CompanySelector.vue";
import {useAreaStore} from "@/store/area";
import {storeToRefs} from "pinia";
import {debounce} from "lodash";
import {modifyLockerArea, checkAreaName, removeLockerAreas} from "@/api/area";

const router = useRouter();
const route = useRoute();
const areaStore = useAreaStore();
const authStore = useAuthStore();

const areaNameValid = ref({
  msg: '',
  valid: true,
});

// 락커 구역 수정 초기상태를 저장할 변수
const initialState = ref({});
const isEdited = ref(false);
const isDataLoaded = ref(false);

const {companyId, areaName} = storeToRefs(areaStore);

onMounted(() => {
  if (!authStore.isSuperAdmin) {
    companyId.value = authStore.companyId;
  }
});

onMounted(async () => {
  // 상태 깊은 복사
  await areaStore.fetchAreaData(route.params.areaId);
  initialState.value = JSON.parse(JSON.stringify(areaStore.$state));
  isDataLoaded.value = true;
});

const inputAreaName = (e) => {
  const inputValue = e.target.value;

  // 입력 값 길이가 12자 이하일 때만 변경
  if (inputValue.length <= 12) {
    areaName.value = inputValue; // 수동으로 값 업데이트
    debouncedCheckAreaName(inputValue); // debounce 처리
  } else {
    // 12자 이상 입력을 시도하면, 값을 자르거나 입력을 방지할 수 있습니다.
    e.target.value = areaName.value; // 값을 12자 이하로 되돌리기
  }
}

const debouncedCheckAreaName = debounce(async (newNm) => {
  if (!newNm) {
    areaNameValid.value.valid = false;
    areaNameValid.value.msg = '구역명을 입력해주세요';
    return;
  }
  if (!companyId.value) {
    alert('업체를 먼저 선택해주세요');
    areaName.value = null;
    return;
  }

  if (initialState._rawValue.areaName == newNm){ // 기존 값
    areaNameValid.value.valid = true;
    areaNameValid.value.msg = '';
    return;
  }

  try {
    const {data} = await checkAreaName(companyId.value, newNm);
    if (data.isDuplicated) {
      areaNameValid.value.valid = false;
      areaNameValid.value.msg = '이미 등록된 구역명입니다.';
    } else {
      areaNameValid.value.valid = true;
      areaNameValid.value.msg = '등록 가능한 구역명입니다.';
    }
  } catch (e) {
    areaNameValid.value.valid = false;
    console.error(e);
  }
},300);

function checkRequired() {
  return !!(companyId.value && areaNameValid.value.valid);
}

function isStateChanged() {
  return !!(companyId.value != initialState._rawValue.companyId || areaName.value != initialState._rawValue.areaName);
}

async function editLockerArea() {
  if (!checkRequired()) {
    alert('락커 구역 정보를 모두 입력해 주세요');
    return;
  }

  if (!isStateChanged()) {
    alert('저장이 완료되었습니다.')
    router.push('/locker/area/list');
    return;
  }

  try {
    const {status} = await modifyLockerArea(route.params.areaId, {
      companyId: companyId.value,
      areaName: areaName.value,
    });
    if (status === 200) {
      alert('저장이 완료되었습니다.')
      await router.push('/locker/area/list');
    }
  } catch (e) {
    console.error(e);
  }
}

async function deleteLockerArea() {
  if (!confirm('삭제하시겠습니까?')) return;

  try {
    const resp = await removeLockerAreas(route.params.areaId);
    if (resp.status === 204) {
      alert('삭제되었습니다.');
      await router.push('/locker/area/list');
    }
  } catch (error) {
    console.error(error);
  }
}

function listLocker(){
  if (isStateChanged()) {
    if (!confirm('입력 중인 내용이 사라집니다.\n계속 하시겠습니까?')) return;
  }
  router.push('/locker/area/list');
}

// function editLockerArea(){
//   if(!alert('저장이 완료도었습니다')){
//     router.push('/locker/area/list');
//   }
// }
// function deleteLockerArea(){
//   if(confirm('삭제하시겠습니까?')){
//     // 락커 구역 삭제...
//     alert('삭제되었습니다.');
//     router.push('/locker/area/list');
//   }
// }
</script>

<style scoped></style>
