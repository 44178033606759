<template>
  <div class="body_left">
    <div class="left_top no_mobile">내 정보</div>
    <div class="left_body">
      <ul class="menu">
        <li v-for="page in pages">
          <router-link
              :to="page.link"
              :class="{ on: checkSideActive(page.link)}"
          >
            {{ page.label }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {checkSideActive} from "@/components/Layout/common/layoutCommon";
import {ref} from "vue";

const pages = ref([
  {label: '내 정보 수정', link: '/myinfo/management'},
]);

</script>

<style scoped></style>
