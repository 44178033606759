// 회원 승인페이지
export const memberStatusOption = [
    {label: '전체보기', value: null},
    {label: '승인', value: 'approved'},
    {label: '미승인', value: 'pending'},
    {label: '반려', value: 'rejected'},
];

// 락커 목록페이지
export const batteryStatusOptions = [
    {label: '전체보기', value: null},
    {label: '양호', value: 'good'}, {label: '교체필요', value: 'replace'},
]
export const activeStatusOptions = [
    {label: '전체보기', value: null},
    {label: '활성', value: true}, {label: '비활성', value: false},
]

// 락커 사용현황페이지
export const usageTypeOptions = [
    { label: '전체보기', value: null },
    { label: '일일락커', value: 'daily' },
    { label: '개인락커', value: 'personal' },
];

export const usageStatusOptions = [
    { label: '전체보기', value: null },
    { label: '사용가능', value: 'available' },
    { label: '사용중', value: 'inUse' },
    { label: '기간만료', value: 'expired' },
];
