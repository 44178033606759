<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_66.png" alt="" />관리자
    </div>
    <div class="left_body">
      <ul class="menu">
        <template v-for="page in pages">
          <li v-if="isRendering(page.super)">
            <router-link
                :to="page.link"
                :class="{ on: checkSideActive(page.link)}"
            >
              {{ page.label }}
            </router-link>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {checkSideActive} from "@/components/Layout/common/layoutCommon";
import {computed, ref} from "vue";
import {useAuthStore} from "@/store/auth";

const authStore = useAuthStore();
const isSuperAdmin = computed(()=>authStore.isSuperAdmin);

const pages = ref([
  {label: '이용약관 목록', link: '/admin/termsofuse/list', super:true},
  {label: '이용약관 등록', link: '/admin/termsofuse/add', super:true},
  {label: '이용약관 상세보기', link: '/admin/termsofuse/view', super:true},
  {label: '개인정보처리방침 목록', link: '/admin/privacy/list', super:true},
  {label: '개인정보처리방침 등록', link: '/admin/privacy/add', super:true},
  {label: '개인정보처리방침 상세보기', link: '/admin/privacy/view', super:true},
  {label: '알림설정', link: '/admin/setting'},
  {label: '휴무일·영업시간 설정', link: '/admin/settingtime'},
]);

function isRendering(isSuperPage){
  if(!isSuperPage) return true;
  return isSuperAdmin.value;
}


</script>

<style scoped></style>
