import {useRoute} from "vue-router";

// 현재 선택된 사이드바의 효과를 주기 위한 함수
function checkSideActive(menuPath){
    return useRoute().path === menuPath;
}

// 현재 선택된 헤더 관리메뉴의 효과를 주기 위한 함수
function checkHeaderActive(menuPath){
    return useRoute().path.startsWith(menuPath);
}

export {
    checkSideActive,
    checkHeaderActive
}