import {lockerUsages} from "@/api";
import {formatDate, formatDateTime} from "@/util/formatUtils";

export function fetchLockerUsages(companyId,filterInfo,orderInfo,searchInfo,pagination){
    return lockerUsages.get('',{
        params:{
            companyId,
            filterDateBy:filterInfo.filterDateBy,
            startDate: formatDate(filterInfo?.dateRange[0]||null),
            endDate: formatDate(filterInfo?.dateRange[1]||null),
            usageType: filterInfo.usageType,
            usageStatus:filterInfo.usageStatus,
            orderKey: orderInfo.orderKey,
            orderValue: orderInfo.orderValue,
            searchKey: searchInfo.searchKey,
            searchValue: searchInfo.searchValue,
            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
        }
    });
}

// 락커 이용 등록 API
export function registerLockerUsage(usageInfo){
    return lockerUsages.post('',usageInfo);
}

// 락커 사용 기간 연장 API
export function extensionLockerUsage(lockerUsageId,extensionDate){
    return lockerUsages.patch(`/${lockerUsageId}/extend`,{
        extensionDate: formatDateTime(extensionDate, '-','Z')
    });
}




