<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">회원 상세보기</div>
        <div class="description">
          회원 정보를 조회하고 이용중인 락커를 관리합니다.
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26"/>
                <col/>
              </colgroup>
              <tr v-if="authStore.isSuperAdmin">
                <th>업체</th>
                <td>
                  <div class="inner_td">{{storeUser.companyName}}</div>
                </td>
              </tr>
              <tr>
                <th>이름</th>
                <td>{{storeUser.storeUserName}}</td>
              </tr>
              <tr>
                <th>휴대폰번호</th>
                <td>
                  <div class="inner_td">{{storeUser.storeUserPhoneNumber}}</div>
                </td>
              </tr>
              <tr>
                <th>생년월일</th>
                <td>
                  <div class="inner_td">{{formatDate(storeUser.birthday)||'-'}}</div>
                </td>
              </tr>
              <tr>
                <th>성별</th>
                <td>
                  <div class="inner_td">{{gender||'-'}}</div>
                </td>
              </tr>
              <tr>
                <th>메모</th>
                <td>
                  {{ storeUser.description || '-'}}
                </td>
              </tr>
            </table>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 12%"/>
                  <col style="width: 8%"/>
                  <col/>
                  <col style="width: 28%"/>
                  <col style="width: 13%"/>
                  <col style="width: 18%"/>
                </colgroup>
                <thead class="no_mobile">
                <tr>
                  <th>
                    <a href="###">등록일</a>
                    <img src="@/images/up_icon_s.png" style="display: none"/>
                    <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                    />
                  </th>
                  <th>
                    <a href="###">유형</a>
                    <img src="@/images/up_icon_s.png" style="display: none"/>
                    <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                    />
                  </th>
                  <th>
                    <a href="###">락커정보</a>
                    <img src="@/images/up_icon_s.png" style="display: none"/>
                    <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                    />
                  </th>
                  <th>
                    <a href="###">이용기간</a>
                    <img src="@/images/up_icon_s.png" style="display: none"/>
                    <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                    />
                  </th>
                  <th>
                    <a href="###">상태</a>
                    <img src="@/images/up_icon_s.png" style="display: none"/>
                    <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                    />
                  </th>
                  <th>관리</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="no_mobile num">2024-07-01</td>
                  <td class="seat o1 o_br">
                    <span class="only_mobile">유형 : </span>
                    <span>개인락커</span>
                  </td>
                  <td class="seat o2 o_title">
                    <span class="only_mobile">락커정보 : </span>
                    <span>[여자탈의실] 237번</span>
                  </td>
                  <td class="num o4 o_br">
                    <span class="only_mobile">이용기간 : </span>
                    <span>2024-07-01 ~ 2024-07-31</span>
                    <br/>
                    <span class="bold"> (31일)</span>
                  </td>
                  <td class="o3">
                    <span class="only_mobile">상태 : </span>
                    <span class="text_green bold">사용중</span>
                  </td>
                  <td class="o_stat">
                    <div class="center_td">
                      <div class="inner_td">
                        <button type="button">연장</button>
                        <button type="button">회수</button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="no_mobile num">2024-07-01</td>
                  <td class="seat o1 o_br">
                    <span class="only_mobile">유형 : </span>
                    <span>개인락커</span>
                  </td>
                  <td class="seat o2 o_title">
                    <span class="only_mobile">락커정보 : </span>
                    <span>[여자탈의실] 237번</span>
                  </td>
                  <td class="num o4 o_br">
                    <span class="only_mobile">이용기간 : </span>
                    <span>2024-07-01</span> <b>15:00</b> ~
                    <span>2024-07-01</span> <b>18:00</b>
                    <br/>
                    <span class="bold">(3시간)</span>
                  </td>
                  <td class="o3">
                    <span class="only_mobile">상태 : </span>
                    <span class="text_red bold">기간만료</span>
                  </td>
                  <td class="o_stat">
                    <div class="center_td">
                      <div class="inner_td">
                        <button type="button">연장</button>
                        <button type="button">회수</button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="no_mobile num">2024-07-01</td>
                  <td class="seat o1 o_br">
                    <span class="only_mobile">유형 : </span>
                    <span>개인락커</span>
                  </td>
                  <td class="seat o2 o_title">
                    <span class="only_mobile">락커정보 : </span>
                    <span>[여자탈의실] 237번</span>
                  </td>
                  <td class="num o4 o_br">
                    <span class="only_mobile">이용기간 : </span>
                    <span>2024-07-01 ~ 2024-07-31</span>
                    <br/>
                    <span class="bold"> (31일)</span>
                  </td>
                  <td class="o3">
                    <span class="only_mobile">상태 : </span>
                    <span class="text_grey bold">사용완료</span>
                  </td>
                  <td class="o_stat">
                    <div class="center_td">
                      <div class="inner_td">
                        <button type="button">삭제</button>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
                <tbody style="display: none">
                <tr class="no_data">
                  <td colspan="8">데이터가 없습니다</td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="btn_bottom_a">
            <router-link :to="`/member/edit/${storeUser.storeUserId}`" class="on">수정</router-link>
            <a @click.prevent="deleteMember" href="###">삭제</a>
            <router-link to="/member/list">목록</router-link>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import {fetchStoreUser} from "@/api/storeUser";
import {useAuthStore} from "@/store/auth";
import {formatDate} from "../../util/formatUtils";

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

const storeUser = ref({
  birthday: null,
  companyId: null,
  companyName: null,
  description: null,
  gender: null,
  storeUserId: null,
  storeUserName: null,
  storeUserPhoneNumber: null,
})

onMounted(async () => {
  try {
    const resp = await fetchStoreUser(route.params.memberId);
    storeUser.value = resp.data;
  } catch (e) {
    console.error(e);
  }
});

const gender = computed(() => {
  switch (storeUser.value.gender) {
    case 'male':
      return '남성';
    case 'female':
      return '여성';
    default:
      return null;
  }
});

function deleteMember() {
  if (confirm('삭제하시겠습니까?')) {
    // 락커 구역 삭제...
    alert('삭제되었습니다.');
    router.push('/member/list');
  }
}
</script>

<style scoped></style>
