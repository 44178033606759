<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">사용자 목록</div>
        <div class="description">사용자를 조회하고 관리합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>가입수단별</th>
                  <td>
                    <FilterTabs  :tabs="tabOptions"
                                 v-model:selectTab="joinOption"
                                 @tab-selected="onTabSelected"/>

                  </td>
                </tr>
                <tr>
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <select>
                        <option>전체</option>
                        <option>휴대폰번호</option>
                        <option>이름</option>
                        <option>이메일</option>
                      </select>
                      <input
                        type="text"
                        class="pop_text"
                        placeholder="검색어를 입력하세요"
                      />
                      <button type="button">검색</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="52" unit="명"
                                 @resultCount="console.log"/>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 7%" />
                  <col />
                  <col style="width: 10%" />
                  <col style="width: 12%" />
                  <col style="width: 13%" />
                  <col style="width: 10%" />
                  <col style="width: 10%" />
                  <col style="width: 11%" />
                  <col style="width: 11%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">이메일</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">이름</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">휴대폰번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">가입수단</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">가입일</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">수정일</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>간편<br />비밀번호</th>
                    <th>관리</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="no_mobile num">2</td>
                    <td class="num phone o4 o_br">
                      <span class="only_mobile">이메일 : </span>
                      <span>admin6640@gamil.com</span>
                    </td>
                    <td class="detail_page_a o1 o_title">
                      <div class="center_td">
                        <router-link to="/user/view" class="center_td">
                          <p class="text_bold long_p">홍길동</p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                    <td class="num phone o4">
                      <span class="only_mobile">휴대폰번호 : </span>
                      <span>010-5588-6655</span>
                    </td>
                    <td class="seat o3">
                      <span class="only_mobile">가입수단 : </span>
                      <span>휴대폰번호</span>
                    </td>
                    <td class="num phone o5">
                      <span class="only_mobile">가입일 : </span>
                      <span>2024-07-15</span>
                    </td>
                    <td class="num phone o7r">
                      <span class="only_mobile">가입일 : </span>
                      <span>2024-07-15</span>
                    </td>
                    <td class="num o8">
                      <div class="center_td">
                        <button type="button">초기화</button>
                      </div>
                    </td>
                    <td class="num o9r">
                      <div class="center_td">
                        <button type="button">강제탈퇴</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody style="display: none">
                  <tr class="no_data">
                    <td colspan="8">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a href="###"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a href="###" class="on">1</a>
              <a href="###">2</a>
              <a href="###">3</a>
              <a href="###"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a href="###"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>

import FilterTabs from "@/components/common/FilterTabs.vue";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";
import {ref} from "vue";

const tabOptions = [
  { label: '전체보기', value: null },
  { label: '이메일', value: 'email' },
  { label: '카카오톡', value: 'kakao' },
  { label: '네이버', value: 'naver' },
  { label: '구글', value: 'google' },
  { label: '애플', value: 'apple' },
];
const joinOption = ref(null);

function onTabSelected(){
  console.log(joinOption.value);
}

</script>

<style scoped></style>
