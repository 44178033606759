<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">락커 제어이력</div>
        <div class="description">락커 잠금해제 이력을 유형별로 조회합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>업체별</th>
                  <td>
                    <div class="inner_td">
                      <select class="w_200">
                        <option>전체</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>기간별</th>
                  <td>
                    <div class="inner_td">
                      <input type="date" /> ~ <input type="date" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>제어유형별</th>
                  <td>
                    <FilterTabs :tabs="tabOptions" @tab-selected="onTabSelected"/>
                  </td>
                </tr>
                <tr>
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <select>
                        <option>전체</option>
                        <option>구역명</option>
                        <option>락커번호</option>
                        <option>이름</option></select
                      ><input
                        type="text"
                        class="pop_text"
                        placeholder="검색어를 입력하세요"
                      /><button type="button">검색</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="42" unit="건"
                                 @resultCount="console.log"/>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 7%" />
                  <col />
                  <col style="width: 18%" />
                  <col style="width: 16%" />
                  <col style="width: 11%" />
                  <col style="width: 18%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">상호명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">제어일시</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">제어유형</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">락커번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">회원명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="num no_mobile">3</td>
                    <td class="seat o0 o_br">
                      <span class="only_mobile">상호명 : </span>
                      <span>주식회사마이즈</span>
                    </td>
                    <td class="num seat o1 o_br">
                      <span class="only_mobile">제어일시 : </span>
                      <span>2024.07.01 15:00:02</span>
                    </td>
                    <td class="num o4r">
                      <span class="only_mobile">제어유형 : </span>
                      <span>App</span>
                    </td>
                    <td class="o2 o_title">
                      <span class="only_mobile">락커번호 : </span>
                      <span>[1F] 1</span>
                    </td>
                    <td class="seat o3 detail_page_a">
                      <span class="only_mobile">회원명 : </span>
                      <div class="center_td">
                        <router-link to="/member/view" class="center_td">
                          <p class="text_bold long_p">서다혜</p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="num no_mobile">3</td>
                    <td class="seat o0 o_br">
                      <span class="only_mobile">상호명 : </span>
                      <span>주식회사마이즈</span>
                    </td>
                    <td class="num seat o1 o_br">
                      <span class="only_mobile">제어일시 : </span>
                      <span>2024.07.01 15:00:02</span>
                    </td>
                    <td class="num o4r">
                      <span class="only_mobile">제어유형 : </span>
                      <span>중계기</span>
                    </td>
                    <td class="o2 o_title">
                      <span class="only_mobile">락커번호 : </span>
                      <span>[1F] 3</span>
                    </td>
                    <td class="seat o3 detail_page_a">
                      <span class="only_mobile">회원명 : </span>
                      <div class="center_td">
                        <router-link to="/member/view" class="center_td">
                          <p class="text_bold long_p">서다혜</p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="num no_mobile">3</td>
                    <td class="seat o0 o_br">
                      <span class="only_mobile">상호명 : </span>
                      <span>주식회사마이즈</span>
                    </td>
                    <td class="num seat o1 o_br">
                      <span class="only_mobile">제어일시 : </span>
                      <span>2024.07.01 15:00:02</span>
                    </td>
                    <td class="num o4r">
                      <span class="only_mobile">제어유형 : </span>
                      <span>관리자</span>
                    </td>
                    <td class="o2 o_title">
                      <span class="only_mobile">락커번호 : </span>
                      <span>[1F] 2</span>
                    </td>
                    <td class="seat o3 detail_page_a">
                      <span class="only_mobile">회원명 : </span>
                      <div class="center_td">
                        <router-link to="/member/view" class="center_td">
                          <p class="text_bold long_p">-</p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="num no_mobile">3</td>
                    <td class="seat o0 o_br">
                      <span class="only_mobile">상호명 : </span>
                      <span>주식회사마이즈</span>
                    </td>
                    <td class="num seat o1 o_br">
                      <span class="only_mobile">제어일시 : </span>
                      <span>2024.07.01 15:00:02</span>
                    </td>
                    <td class="num o4r">
                      <span class="only_mobile">제어유형 : </span>
                      <span>관리자</span>
                    </td>
                    <td class="o2 o_title">
                      <span class="only_mobile">락커번호 : </span>
                      <span>[1F] 2</span>
                    </td>
                    <td class="seat o3 detail_page_a">
                      <span class="only_mobile">회원명 : </span>
                      <div class="center_td">
                        <router-link to="/member/view" class="center_td">
                          <p class="text_bold long_p">서다혜</p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody style="display: none">
                  <tr class="no_data">
                    <td colspan="8">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a href="###"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a href="###" class="on">1</a>
              <a href="###">2</a>
              <a href="###">3</a>
              <a href="###"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a href="###"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import FilterTabs from "@/components/common/FilterTabs.vue";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";

const tabOptions = [
  {label:'전체보기',value:'all'},
  {label:'APP',value:'app'},
  {label:'중계기',value:'relay'},
  {label:'관리자',value:'admin'},
]

function onTabSelected(tabValue){
  console.log(tabValue);
}

</script>

<style scoped></style>
