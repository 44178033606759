<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">락커 사용현황</div>
        <div class="description"></div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%"/>
                <col/>
              </colgroup>
              <tbody>
              <tr v-if="authStore.isSuperAdmin">
                <th>업체별</th>
                <td>
                  <div class="inner_td">
                    <CompanySelector v-model:companyId="companyId"/>
                  </div>
                </td>
              </tr>
              <tr>
                <th>이용기간</th>
                <td>
                  <div class="inner_td m_date">
                    <select v-model="filterInfo.filterDateBy">
                      <option value="usageStartTime">시작일</option>
                      <option value="usageEndTime">만료일</option>
                    </select>
                    <DatePicker class="w_300" v-model="filterInfo.dateRange"
                                :format="'yyyy-MM-dd'"
                                :enable-time-picker="false" auto-apply
                                placeholder="이용기간"
                                locale="ko" range
                    />
                    <!--                                  @update:modelValue="fetchApprovalList"-->
                  </div>
                </td>
              </tr>
              <tr>
                <th>유형</th>
                <td>
                  <FilterTabs :tabs="usageTypeOptions"
                              v-model:select-tab="filterInfo.usageType"
                              @tab-selected="onTabSelected"/>
                </td>
              </tr>
              <tr>
                <th>사용상태</th>
                <td>
                  <FilterTabs :tabs="usageStatusOptions"
                              v-model:select-tab="filterInfo.usageStatus"
                              @tab-selected="onTabSelected"/>
                </td>
              </tr>
              <tr>
                <th>키워드검색</th>
                <td>
                  <div class="inner_td">
                    <select v-model="searchInfo.searchKey">
                      <option value="storeUserName">이름</option>
                      <option value="phoneNumber">휴대폰번호</option>
                    </select>
                    <input
                        type="text"
                        class="pop_text"
                        placeholder="검색어를 입력하세요"
                        v-model="searchInfo.searchValue"
                        @keydown.enter="fetchLockerUsagesInfo"
                    />
                    <button type="button" @click="fetchLockerUsagesInfo">검색</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="pagination.totalCount" unit="건"
                                 v-model:search-result-count="pagination.pageSize"
                                 @resultCount="setPageSize"/>
            <button type="button">잠금해제</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 5%"/>
                  <col v-if="authStore.isSuperAdmin"/>
                  <col style="width: 9%"/>
                  <col style="width: 13%"/>
                  <col style="width: 13%"/>
                  <col style="width: 9%"/>
                  <col style="width: 13%"/>
                  <col style="width: 10%"/>
                  <col style="width: 16%"/>
                </colgroup>
                <thead class="no_mobile">
                <tr>
                  <th>
                    <input type="checkbox" v-model="isAllSelected"/>
                  </th>
                  <template v-for="sortField in sortFieldsState" :key="sortField.key">
                    <th v-if="!sortField.super || authStore.isSuperAdmin">
                      <TableHeaderItem :headData="sortField" @sort="setOrderBy"/>
                    </th>
                  </template>
                </tr>
                </thead>
                <tbody>
                <template v-if="lockerUsages.length !==0">
                  <LockerUseStatusListItem v-for="usage in lockerUsages"
                                           :key="usage.lockerId"
                                           :usageInfo="usage"
                                           @onExtensionModal="extensionModalOn"
                                           @onAssigmentModal="assigmentModalOn"
                  />
                </template>
                <tr class="no_data" v-else>
                  <td :colspan="authStore.isSuperAdmin?9:8">데이터가 없습니다</td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <a href="###">잠금해제</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <Pagination v-model:pageInfo="pagination"/>
          </div>
        </article>
      </div>
    </div>
  </div>
  <!-- Modal: 락커배정 -->
  <AssigmentModal v-if="assigmentModal" v-model:modal="assigmentModal"
                  v-model:modalData="assigmentModalData"
                  @complete="reloadDataOnModalClose"
  />
  <!-- //Modal: 락커배정 -->

  <!-- Modal: 락커연장 -->
  <ExtensionModal v-if="extensionModal" v-model:modal="extensionModal"
                  v-model:modalData="extensionModalData"
                  @complete="reloadDataOnModalClose"
  />
  <!-- //Modal: 락커연장 -->
</template>

<script setup>
import FilterTabs from "@/components/common/FilterTabs.vue";
import {onMounted, ref, watch} from "vue";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";
import {usageStatusOptions, usageTypeOptions} from "@/util/filterFields";
import {useAuthStore} from "@/store/auth";
import CompanySelector from "@/components/common/CompanySelector.vue";
import Pagination from "@/components/common/Pagination.vue";
import TableHeaderItem from "@/components/common/TableHeaderItem.vue";
import {useOrder} from "@/composables/orderBy";
import {lockerUsageStatusListSortFields} from "@/util/sortFields";
import LockerUseStatusListItem from "@/components/locker/LockerUseStatusListItem.vue";
import AssigmentModal from "@/components/locker/AssigmentModal.vue";
import ExtensionModal from "@/components/locker/ExtensionModal.vue";
import {fetchLockerUsages} from "@/api/locker/lockerUsage";
import {useSelectAll} from "@/composables/selectAll";
import {fetchDefaultDailyDuration} from "@/api/company";

// 락커배정 모달
const assigmentModal = ref(false);
const assigmentModalData = ref({});
// 기간연장 모달
const extensionModal = ref(false);
const extensionModalData = ref({});
const authStore = useAuthStore();

const companyId = ref(authStore.isSuperAdmin ? null : authStore.companyId);

const {
  sortFieldsState,
  getOrderBy,
  resetOrderBy,
  setOrderBy
} = useOrder(lockerUsageStatusListSortFields, fetchLockerUsagesInfo);

const lockerUsages = ref([]);
const {isAllSelected, getSelectedIds} = useSelectAll(lockerUsages, 'lockerId');

const filterInfo = ref({
  usageType: null,
  usageStatus: null,
  filterDateBy: 'usageStartTime',
  dateRange: [],
});

const searchInfo = ref({
  searchKey: 'storeUserName',
  searchValue: null,
});

const pagination = ref({
  pageNumber: 1,
  pageSize: 5,
  totalCount: 0
});


onMounted(async () => {
  await fetchLockerUsagesInfo();
});

watch([filterInfo,
  () => pagination.value.pageSize,
  () => pagination.value.pageNumber,
  companyId,
], fetchLockerUsagesInfo, {deep: true});


async function fetchLockerUsagesInfo() {
  try {
    const {data} = await fetchLockerUsages(companyId.value, {
          filterDateBy: filterInfo.value.filterDateBy,
          usageType: filterInfo.value.usageType,
          usageStatus: filterInfo.value.usageStatus,
          dateRange: filterInfo.value.dateRange || [],
        }, {
          orderKey: getOrderBy()?.key,
          orderValue: getOrderBy()?.direction
        },
        searchInfo.value,
        pagination.value);
    console.log(data);
    pagination.value.pageNumber = data.pageNumber;
    pagination.value.pageSize = data.pageSize;
    pagination.value.totalCount = data.totalCount;

    lockerUsages.value = data.lockerUsageMetaDataList;
    lockerUsages.value.forEach(usage => usage.isSelected = false);

  } catch (e) {
    console.error(e);
  }
}

function extensionModalOn(lockerInfo) {
  extensionModalData.value = lockerInfo;
  extensionModal.value = true;
}

async function assigmentModalOn(lockerInfo) {
  assigmentModalData.value = lockerInfo;
  try {
    const {data} = await fetchDefaultDailyDuration(lockerInfo.companyId);
    assigmentModalData.value.dailyDefaultDuration = data.dailyDefaultDuration;
    assigmentModal.value = true;
  } catch (e) {
    console.error(e);
  }
}

function reloadDataOnModalClose() {
  fetchLockerUsagesInfo();
  assigmentModal.value = false;
  extensionModal.value = false;
}

function onTabSelected() {
  pagination.value.pageNumber = 1;
  resetOrderBy();
  fetchLockerUsagesInfo();
}

function setPageSize() {
  resetOrderBy();
  pagination.value.pageNumber = 1;
  fetchLockerUsagesInfo();
}


</script>

<style scoped>
.inner_td.m_date {
  flex-wrap: nowrap;
}
</style>
