import {computed, ref} from "vue";

export function useSelectAll(items,idKey){
    const selectItems = ref(items);

    const isAllSelected = computed({
        get: () => selectItems.value.every(item => item.isSelected) && selectItems.value.length > 0,
        set: (val) => selectItems.value.forEach(item => item.isSelected = val),
    });

    function getSelectedIds(){
        return selectItems.value.filter(item=>item.isSelected).map(item=>item[idKey]);
    }

    return {isAllSelected,getSelectedIds};
}