<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_54.png" alt="" />모니터링
    </div>
    <div class="left_body">
      <ul class="menu">
        <li  v-for="page in pages">
          <router-link
              :to="page.link"
              :class="{ on: checkSideActive(page.link)}"
          >
            {{page.label}}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {checkSideActive} from "@/components/Layout/common/layoutCommon";
import {ref} from "vue";

const pages = ref([
  {label:'현황',link:'/monitoring/view'},
]);
</script>

<style scoped></style>
