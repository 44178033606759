<template>
  <header class="header">
    <div class="header_top">
      <div class="header_topbox">
        <div class="header_top_column">
          <a href="https://mizeinc.co.kr" target="_blank">MIZE INC.</a>
        </div>
        <div class="header_top_column">
          <!-- Vue 데이터 바인딩해야 함 -->
          <span class="top_name">{{ authStore.username }}님 </span>
          <router-link to="/myinfo/management"
                       :class="{'active-myinfo' : checkHeaderActive('/myinfo')}"
          >
            내 정보
          </router-link>
          <a href="#" @click.prevent="logout">로그아웃</a>
        </div>
      </div>
    </div>
    <nav class="header_bottom">
      <div class="header_bottombox">
        <div @click="router.push('/locker/use/usestatus')" class="logo">
          <img src="@/images/logo_w.png" />
        </div>
        <div class="menu">
          <router-link
            to="/company/management"
            :class="{ on: checkHeaderActive('/company') }"
            v-if="isSuperAdmin"
          >
            <img src="@/images/topic_thum_69.png" alt="" />업체관리
          </router-link>
          <router-link
            to="/user/management"
            :class="{ on: checkHeaderActive('/user') }"
            v-if="isSuperAdmin"
          >
            <img src="@/images/topic_thum_65.png" alt="" />사용자관리
          </router-link>
          <router-link
            to="/locker/management"
            :class="{ on: checkHeaderActive('/locker') }"
          >
            <img src="@/images/topic_thum_57.png" alt="" />락커관리
          </router-link>
          <router-link
            to="/member/management"
            :class="{ on: checkHeaderActive('/member') }"
          >
            <img src="@/images/topic_thum_75.png" alt="" />회원관리
          </router-link>
          <router-link
            to="/admin/management"
            :class="{ on: checkHeaderActive('/admin') }"
          >
            <img src="@/images/topic_thum_73.png" alt="" />관리자
          </router-link>
          <router-link
            to="/monitoring/view"
            :class="{ on: checkHeaderActive('/monitoring') }"
          >
            <img src="@/images/topic_thum_55.png" alt="" />모니터링
          </router-link>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {checkHeaderActive} from "@/components/Layout/common/layoutCommon";
import {useAuthStore} from "@/store/auth";
import {computed, onMounted} from "vue";
import {resetStorage} from "@/util/authToken";
import {fetchMyInfo} from "@/api/managedUser";

const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();

const isSuperAdmin = computed(()=>authStore.isSuperAdmin);

// onMounted(async ()=>{
//
//   try{
//     const {data, status} = await fetchMyInfo();
//     if(status === 200){
//       authStore.username = data.name;
//       authStore.companyId = data.companyId;
//     }else{
//       authStore.logout();
//       await router.push('/login')
//     }
//   }catch(e){
//     console.error(e);
//     authStore.logout();
//     await router.push('/login')
//   }
// });

function checkAuth() {
  const token_userid = localStorage.getItem("userid");

  if (!token_userid) {
    router.push("/login");
  } else if (route.path === "/") {
    router.push("/locker/use/usestatus");
  }
}
function logout() {
  if (confirm("로그아웃 하시겠습니까?")) {
    alert("로그아웃 되었습니다.");
    authStore.logout();
    router.push('/login');
  }
}

</script>

<style scoped>
.logo{
  cursor:pointer;
}
.active-myinfo{
  color:#fff;
}
</style>
