<template>
  <tr>
    <td class="o1">
      <input type="checkbox" v-model="lockerInfo.isSelected"/>
    </td>
    <td class="no_mobile num">{{lockerInfo.number}}</td>
    <td class="seat o3" v-if="authStore.isSuperAdmin">
      <span class="only_mobile">상호명 : </span>
      <span>{{lockerInfo.companyName}}</span>
    </td>
    <td class="detail_page_a o2 o_title">
      <div class="center_td">
        <router-link :to="`/locker/admin/view/${lockerInfo.lockerId}`" class="center_td">
          <p class="text_bold long_p">[{{lockerInfo.areaName}}] {{lockerInfo.lockerNum}}</p>
          <img src="@/images/detail_page.png" />
        </router-link>
      </div>
    </td>
    <td class="num seat o3r">
      <span class="only_mobile">시리얼넘버 : </span>
      <span>{{lockerInfo.serialNumber}}</span>
    </td>
    <td class="num o7">
      <span class="only_mobile">PIN번호 : </span>
      <span>{{lockerInfo.pinNumber}}</span>
    </td>
    <td class="o7r">
      <span class="only_mobile">배터리상태 : </span>
      <span :class="batteryStatus.class">{{ batteryStatus.status }}</span>
<!--      <span class="text_red bold">교체필요 (30%)</span>-->
    </td>
    <td class="o7">
      <div class="center_td">
        <button type="button">잠금해제</button>
      </div>
    </td>
    <td class="num o_stat">
      <input
          type="checkbox"
          :id="`locker-active${lockerInfo.lockerId}`"
          class="toggle"
          hidden
          checked
          v-model="isActive"
      />
      <label @click="setFlag" class="toggleSwitch">
        <span class="toggleButton"></span>
      </label>
    </td>
  </tr>
</template>

<script setup>

import {computed} from "vue";
import {useAuthStore} from "@/store/auth";

const authStore = useAuthStore();

const props = defineProps({
  lockerInfo: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['setActive']);

const isActive = computed(()=>!!props.lockerInfo.isActive);

const batteryStatus = computed(()=>{
  if(!props.lockerInfo.batteryLevel){
    return {
      class:'',
      status: '-'
    }
  }
  if (props.lockerInfo.batteryLevel >10){
    return {
      class:'',
      status:`양호 (${props.lockerInfo.batteryLevel}%)`
    }
  }else{
    return {
      class:'text_red bold',
      status:`교체필요 (${props.lockerInfo.batteryLevel}%)`
    }
  }
});

function setFlag(){
  emit('setActive',{
    lockerId:props.lockerInfo.lockerId,
    flag: !isActive.value,
  });
}

</script>

<style  scoped>

</style>