<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_74.png" alt=""/>회원관리
    </div>
    <div class="left_body">
      <ul class="menu">
        <li v-for="page in pages">
          <router-link
              :to="page.link"
              :class="{ on: checkSideActive(page.link)}"
          >
            {{ page.label }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import {checkSideActive} from "@/components/Layout/common/layoutCommon";

const pages = ref([
  {label: '회원 목록', link: '/member/list'},
  {label: '회원 등록', link: '/member/add'},
  {label: '회원 승인관리', link: '/member/approval/list'},
]);
</script>

<style scoped></style>
