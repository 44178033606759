<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">락커 일괄등록</div>
        <div class="description">
          제공해드리는 엑셀파일을 이용해 락커를 일괄등록 할 수 있습니다.<br/>
          파일 업로드 및 내용 검토 후 페이지 하단 '일괄 등록하기'를 클릭하시면
          일괄등록이 완료 됩니다.
          <br/>*엑셀 업로드는 회당 최대 200개로 제한됩니다.
        </div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%"/>
                <col/>
              </colgroup>
              <tbody>
              <tr v-if="authStore.isSuperAdmin">
                <th>업체선택<span class="text_red">*</span></th>
                <td>
                  <CompanySelector v-model:companyId="companyId"
                                   :is-view-mode="false"
                                   :is-disabled="isUploaded"
                  />
                </td>
              </tr>
              <tr>
                <th>구역선택<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <select class="w_250" v-model="areaId" :disabled="companyId===null || isUploaded">
                      <option disabled :value="null">구역을 선택하세요</option>
                      <option v-for="area in areas" :value="area.areaId">{{ area.areaName }}</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>파일등록<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input
                        type="file" accept=".xls,.xlsx"
                        class="w_250" ref="fileInput"
                        placeholder="선택된 파일 없음"
                        @click="handleFileClick"
                        @change="(e)=>isUploaded = !!e.target.files[0]"
                    />
                    <button @click="downloadExcel" type="button">엑셀양식 다운받기</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a @click.prevent="renderExcelData" class="on">파일 업로드</a>
          </div>
          <!-- //검색툴 -->
          <!-- 업로드 내용확인: 엑셀 업로드 완료시에만 표시 -->
          <div class="count_page">
            <div class="count_num">
              <span>총</span>
              <span class="accent_color"> {{ lockers.length }} </span>
              <span>건</span>
            </div>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 8%"/>
                  <col style="width: 16%"/>
                  <col style="width: 16%"/>
                  <col style="width: 16%"/>
                  <col/>
                  <col style="width: 9%"/>
                  <col style="width: 8%"/>
                </colgroup>
                <thead class="no_mobile">
                <tr>
                  <th v-for="sortField in sortFieldsState" :key="sortField.key">
                    <TableHeaderItem :headData="sortField"/>
                  </th>
                </tr>
                </thead>
                <tbody>
                <template v-if="lockers.length>0">
                  <LockerBatchListItem v-for="(locker,i) in lockers" :key="locker.__rowNum__"
                                       v-model:locker="lockers[i]"
                                       :tablets="tablets"
                                       @remove="removeRow"
                                       @validate="validateLocker"
                  />
                </template>
                <tr class="no_data" v-else>
                  <td colspan="8">데이터가 없습니다</td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="btn_bottom_a">
            <a href="###" class="on">일괄등록하기</a>
          </div>
          <!-- //업로드 내용확인: 엑셀 업로드 완료시에만 표시 -->
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useAuthStore} from "@/store/auth";
import CompanySelector from "@/components/common/CompanySelector.vue";
import {onMounted, ref, watch} from "vue";
import {fetchAreaList} from "@/api/area";
import {useOrder} from "@/composables/orderBy";
import TableHeaderItem from "@/components/common/TableHeaderItem.vue";
import {lockerBatchFields} from "@/util/sortFields";
import * as XLSX from 'xlsx/xlsx.mjs';
import LockerBatchListItem from "@/components/locker/LockerBatchListItem.vue";
import {fetchTabletList} from "@/api/tablet";
import {useRouter} from "vue-router";

const authStore = useAuthStore();
const router = useRouter();
const {sortFieldsState} = useOrder(lockerBatchFields, null);

const companyId = ref(authStore.isSuperAdmin ? null : authStore.companyId);
const areaId = ref(null);

const areas = ref([]);
const lockers = ref([]);
const tablets = ref([]);
const isUploaded = ref(false);
const fileInput = ref(null);

onMounted(() => {
  if (!companyId.value) return;
  setAreas();
});

watch(companyId, () => {
  areaId.value = null;
  setAreas();
});

watch(areaId, async (id) => {
  if (!id) return;
  try {
    const {status, data} = await fetchTabletList(id);
    if (status === 200) {
      if(data.length === 0 ){
        alert('해당 구역에 등록된 중계기가 없습니다. 중계기를 먼저 등록해주세요');
        await router.push('/locker/relay/add');
        return;
      }
      tablets.value = data;
    }
  } catch (e) {
    console.error(e);
  }
});

async function setAreas() {
  try {
    const {status, data} = await fetchAreaList(companyId.value);
    if (status === 200) {
      areas.value = data;
    }
  } catch (e) {
    console.error(e);
  }
}

function downloadExcel() {
  const link = document.createElement('a');
  link.href = '/asset/locker-batch-add.xlsx';
  link.download = '락커_일괄등록_양식.xlsx'
  link.click();
}

function handleFileClick(e) {
  if (!companyId.value) {
    alert('업체를 먼저 선택해주세요');
    e.preventDefault();
    return;
  }
  if (!areaId.value) {
    alert('구역을 먼저 선택해주세요');
    e.preventDefault();
  }
}

function renderExcelData() {
  const file = fileInput.value.files[0];
  if (!file) {
    alert('업로드할 파일이 존재하지 않습니다.');
    return;
  }
  const reader = new FileReader();

  reader.onload = function () {
    const fileData = reader.result;
    const wb = XLSX.read(fileData, {type: 'binary'});
    wb.SheetNames.forEach(function (sheetName) {
      let rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
      // 엑셀의 데이터를 200개 까지만 등록
      rowObj = rowObj.slice(0, 200);

      rowObj.forEach(obj => {
        // 한글로 되어있는 엑셀의 key값을 사용하기 편하게 영어로 변환
        delete Object.assign(obj, {lockerNum: obj['락커번호']})['락커번호'];
        delete Object.assign(obj, {serialNumber: obj['시리얼넘버']})['시리얼넘버'];
        delete Object.assign(obj, {pinNumber: obj['PIN번호']})['PIN번호'];

        obj.tabletId = tablets.value[0].tabletId;
        obj.companyId = companyId.value;
        obj.areaId = areaId.value;
        obj.isValid = false;
      });
      // TODO 일괄 유효성검사 API적용 해야함
      lockers.value = rowObj.reverse();
    });
  };
  reader.readAsBinaryString(file);
}

function removeRow(rowNum) {
  if (!confirm('항목을 삭제하시겠습니까?')) return;
  lockers.value = lockers.value.filter(i => i.__rowNum__ !== rowNum);
  alert('삭제되었습니다.');
}

function validateLocker(rowNum){
  const target = lockers.value.find(i=>i.__rowNum__===rowNum);

//   TODO 엑셀파일에 의해 등록된 락커들과의 중복검사
//   TODO API를 통해 서버에 등록된 락커들과 중복검사
}

function checkExcelValid(target){
  return  lockers.value.every(i=>
      i.lockerNum !== target.lockerNum &&
      i.serialNumber !== target.serialNumber
  );
}


</script>

<style scoped></style>
