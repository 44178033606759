import {defineStore} from "pinia";
import {ref} from "vue";
import {fetchLocker} from "@/api/locker/locker";

export const useLockerStore = defineStore('locker', () => {
    const companyId = ref(0);
    const companyName = ref('');
    const areaId = ref(0);
    const areaName = ref('');
    const tabletId = ref(0);
    const tabletName = ref('');
    const lockerNum = ref(0);
    const batteryLevel = ref(0);
    const serialNumber = ref('');
    const pinNumber = ref('');

    const lockerNumbValid = ref({
        msg: '',
        valid: false,
      });
      const serialNumValid = ref({
        msg: '',
        valid: false,
      });

    async function fetchLockerData(id) {
        try {
            const {data} = await fetchLocker((id));
            console.log(data);

            companyId.value = data.companyId;
            companyName.value = data.companyName;
            areaId.value = data.areaId;
            areaName.value = data.areaName;
            tabletId.value = data.tabletId;
            tabletName.value = data.tabletName;
            lockerNum.value = data.lockerNum;
            batteryLevel.value = data.batteryLevel;
            serialNumber.value = data.serialNumber;
            pinNumber.value = data.pinNumber;
        } catch (error) {
            console.error(error);
        }
    }

    return {
        companyId,
        companyName,
        areaId,
        areaName,
        tabletId,
        tabletName,
        lockerNum,
        batteryLevel,
        serialNumber,  
        pinNumber,
        lockerNumbValid,
        serialNumValid,
        fetchLockerData,
    }
});