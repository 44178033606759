<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">락커 중계기 등록</div>
        <div class="description">
          락커 중계기를 등록합니다.<br />
          (*)표시는 필수 입력 정보입니다.
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>업체선택<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <select class="w_250">
                        <option>업체를 선택하세요</option>
                        <option>마이즈</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>중계기명<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        class="w_250"
                        placeholder="중계기명을 입력하세요"
                      />
                      <div class="validation text_red">
                        이미 등록된 중계기명입니다.
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>시리얼넘버<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        class="w_250"
                        placeholder="시리얼넘버를 입력하세요"
                      />
                      <div class="validation text_red">
                        이미 등록된 시리얼넘버입니다.
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>IP주소<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        class="w_250"
                        placeholder="IP주소를 입력하세요"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>설치구역<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <select class="w_250">
                        <option>설치구역을 선택하세요</option>
                        <option>1F</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>비고</th>
                  <td>
                    <div class="inner_td">
                      <input type="text" class="w_250" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a @click.prevent="addLockerRelay" href="###" class="on">등록</a>
            <router-link to="/locker/relay/list">취소</router-link>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";

const router = useRouter();

function addLockerRelay(){
  if(!alert('등록되었습니다.')){
    router.push('/locker/relay/list')
  }
}
</script>

<style scoped></style>
