<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">락커 목록</div>
        <div class="description">락커를 관리합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%"/>
                <col/>
              </colgroup>
              <tbody>
              <tr v-if="authStore.isSuperAdmin">
                <th>업체별</th>
                <td>
                  <div class="inner_td">
                    <CompanySelector v-model:companyId="companyId"/>
                  </div>
                </td>
              </tr>
              <tr>
                <th>구역별</th>
                <td>
                  <div class="inner_td">
                    <select class="w_200" v-model="areaId" :disabled="companyId===null">
                      <option :value="null">전체</option>
                      <option v-for="area in areas" :value="area.areaId">{{ area.areaName }}</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>배터리상태</th>
                <td>
                  <FilterTabs v-model:selectTab="searchInfo.batteryStatus"
                              :tabs="batteryStatusOptions"/>
                </td>
              </tr>
              <tr>
                <th>활성화여부</th>
                <td>
                  <FilterTabs v-model:selectTab="searchInfo.isActive"
                              :tabs="activeStatusOptions"/>
                </td>
              </tr>
              <tr>
                <th>키워드검색</th>
                <td>
                  <div class="inner_td">
                    <select v-model="searchInfo.searchKey">
                      <option value="lockerNum">락커번호</option>
                      <option value="serialNumber">시리얼넘버</option>
                      <option value="pinNumber">PIN번호</option>
                    </select
                    ><input
                      type="text"
                      class="pop_text"
                      placeholder="검색어를 입력하세요"
                      v-model="searchInfo.searchValue"
                      @keydown.enter="fetchLockersInfo"
                  />
                    <button type="button" @click="fetchLockersInfo">검색</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="pagination.totalCount" unit="건"
                                 v-model:searchResultCount="pagination.pageSize"
                                 @resultCount="setPageSize"
                                 />
            <button @click="router.push('/locker/admin/add')" type="button">락커등록</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 6%"/>
                  <col style="width: 5%"/>
                  <col v-if="authStore.isSuperAdmin"/>
                  <col style="width: 9%"/>
                  <col style="width: 13%"/>
                  <col style="width: 9%"/>
                  <col style="width: 13%"/>
                  <col style="width: 11%"/>
                  <col style="width: 11%"/>
                </colgroup>
                <thead class="no_mobile">
                <tr>
                  <th>
                    <input type="checkbox" v-model="isAllSelected"/>
                  </th>
                  <template v-for="sortField in sortFieldsState" :key="sortField.key ">
                    <th v-if="!sortField.super || authStore.isSuperAdmin">
                      <TableHeaderItem :headData="sortField"/>
                    </th>
                  </template>
                </tr>
                </thead>
                <tbody>
                <template v-if="lockers.length !== 0">
                  <LockerListItem v-for="locker in lockers"
                                  :key="locker.lockerId"
                                  :lockerInfo="locker"
                                  @setActive="setLockerActive"/>
                </template>
                <tr class="no_data" v-else>
                  <td :colspan="authStore.isSuperAdmin?9:8">데이터가 없습니다</td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <a @click.prevent="removeLocker">삭제</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <Pagination v-model:pageInfo="pagination"/>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter, useRoute} from "vue-router";
import FilterTabs from "@/components/common/FilterTabs.vue";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";
import {useAuthStore} from "@/store/auth";
import CompanySelector from "@/components/common/CompanySelector.vue";
import {onMounted, ref, watch} from "vue";
import {fetchLockers, modifyLockerActive, removeLockers} from "@/api/locker/locker";
import Pagination from "@/components/common/Pagination.vue";
import {useOrder} from "@/composables/orderBy";
import {lockerListSortFields} from "@/util/sortFields";
import TableHeaderItem from "@/components/common/TableHeaderItem.vue";
import LockerListItem from "@/components/locker/LockerListItem.vue";
import {useSelectAll} from "@/composables/selectAll";
import {fetchAreaList} from "@/api/area";
import {activeStatusOptions, batteryStatusOptions} from "@/util/filterFields";

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

const queryCompanyId = route.query.companyId;
const queryAreaId = route.query.areaId;

const {sortFieldsState} = useOrder(lockerListSortFields, fetchLockersInfo());

const companyId = ref(authStore.isSuperAdmin?null:authStore.companyId);
const areaId = ref(null);

onMounted(() => {
  if (queryCompanyId != undefined)
    companyId.value = queryCompanyId;
});

const lockers = ref([]);
const areas = ref([]);

const {isAllSelected, getSelectedIds} = useSelectAll(lockers, 'lockerId');

const pagination = ref({
  pageNumber: 1,
  pageSize: 5,
  totalCount: 0
});

const searchInfo = ref({
  batteryStatus: null,
  isActive: null,
  searchKey: 'lockerNum',
  searchValue: null,
});

onMounted(async () => {
  await fetchLockersInfo();
});

watch(companyId, async (newCompanyId) => {
  if (queryAreaId != undefined){
    areaId.value = queryAreaId;
  }
  else{
    areaId.value = null;
  }

  if(newCompanyId === null){
    await fetchLockersInfo();
    return;
  }

  try {
    const {data, status} = await fetchAreaList(newCompanyId);
    if (status === 200) {
      areas.value = data;
      await fetchLockersInfo();
    }
  } catch (e) {
    console.error(e);
  }
});

watch(areaId, (newAreaId) => {
  if (!newAreaId) return;
  fetchLockersInfo();
});

watch([()=>searchInfo.value.isActive,()=>searchInfo.value.batteryStatus,
      ()=>pagination.value.pageNumber],
    fetchLockersInfo);

async function fetchLockersInfo() {
  try {
    const {data} = await fetchLockers(companyId.value, areaId.value,
        searchInfo.value,pagination.value);

    console.log(data);

    pagination.value.pageNumber = data.pageNumber;
    pagination.value.pageSize = data.pageSize;
    pagination.value.totalCount = data.totalCount;

    lockers.value = data.lockerMetaDataList;
    lockers.value.forEach(locker => locker.isSelected = false);
  } catch (e) {
    console.error(e);
  }
}

async function setLockerActive({ lockerId, flag }) {
  const msg = flag?'활성화':'비활성화';
  if(flag){
    if(!confirm(`${msg} 처리하시겠습니까?`)) return;
  }

  try {
    const { status } = await modifyLockerActive(lockerId, flag);
    if (status === 200) {
      alert(`${msg}되었습니다.`);
      const locker = lockers.value.find(l => l.lockerId === lockerId);
      if (locker) locker.isActive = flag;
    }
  } catch (e) {
    console.log(e.status);
    if(e.status === 400 && e.response.data.errorCode ==="Locker already assigned"){
      if(!confirm('사용 중인 락커입니다. 락커 회수 후 다시 시도해 주세요')) return ;
      // TODO 락커 사용현황 페이지 해당락커 필터건 후 페이지 이동
      router.push(`/locker/use/usestatus`);
    }
    console.error(e);
  }
}

function setPageSize(){
  pagination.value.pageNumber =1;
  fetchLockersInfo();
}

async function removeLocker() {
  const ids = getSelectedIds();
  if(ids.length === 0){
    alert('선택된 항목이 없습니다.')
    return;
  }
  if(!confirm('삭제하시겠습니까?')) return;

  try{
    const resp = await removeLockers(ids.join(','));
    console.log(resp)
    if(resp.status === 204){
      alert('삭제되었습니다.');
      await fetchLockersInfo();
    }
  }catch(error){
    console.error(error);
  }

}

</script>

<style scoped></style>
