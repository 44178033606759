<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">이용약관 목록</div>
        <div class="description">이용약관을 조회하고 관리할 수 있습니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>기간별</th>
                  <td>
                    <div class="inner_td">
                      <input type="date" /> ~ <input type="date" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>상태별</th>
                  <td>
                    <FilterTabs :tabs="statusOptions" @tab-selected="onStatusSelected"/>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="42" unit="건"
                                 @resultCount="console.log"/>
            <button @click="router.push('/admin/termsofuse/add')" type="button">등록하기</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 6%" />
                  <col style="width: 7%" />
                  <col />
                  <col style="width: 12%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th><input type="checkbox" /></th>
                    <th>
                      <a>번호</a
                      ><img
                        src="@/images/up_icon_s.png"
                        style="display: none"
                      /><img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">시행일</a
                      ><img
                        src="@/images/up_icon_s.png"
                        style="display: none"
                      /><img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no_move">
                    <td class="o1"><input type="checkbox" /></td>
                    <td class="no_mobile">2</td>
                    <td class="detail_page_a long_txt o2 o_title">
                      <div>
                        <router-link to="/admin/termsofuse/view" class="center_td"
                          ><p class="text_bold" style="max-width: 280px">
                            2024년 07월 29일
                          </p>
                          <img src="@/images/detail_page.png"
                        /></router-link>
                      </div>
                    </td>
                    <td class="center_td o_stat">
                      <div class="stat back_green"><span>표시</span></div>
                    </td>
                  </tr>
                  <tr class="no_move">
                    <td class="o1"><input type="checkbox" /></td>
                    <td class="no_mobile">1</td>
                    <td class="detail_page_a long_txt o2 o_title">
                      <div>
                        <router-link to="/admin/termsofuse/view" class="center_td"
                          ><p class="text_bold" style="max-width: 280px">
                            2024년 07월 23일
                          </p>
                          <img src="@/images/detail_page.png"
                        /></router-link>
                      </div>
                    </td>
                    <td class="center_td o_stat">
                      <div class="stat back_red"><span>미표시</span></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div><a>삭제</a><a>표시</a><a>미표시</a></div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <!--v-if--><!--v-if--><a href="###" class="on">1</a
              ><!--v-if--><!--v-if-->
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>
<script setup>
import {useRouter} from "vue-router";
import FilterTabs from "@/components/common/FilterTabs.vue";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";

const router = useRouter();

const statusOptions = [
  {label:'전체보기',value:'all'},
  {label:'표시',value:'displayed'},
  {label:'미표시',value:'notDisplayed'},
];

function onStatusSelected(statusValue){
  console.log(statusValue);
}

</script>

<style scoped></style>
