<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">이용약관 등록</div>
        <div class="description">이용약관을 등록합니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>표시상태<span class="text_red">*</span></th>
                <td>
                  <FilterTabs :tabs="statusOptions" @tab-selected="onStatusSelected"/>
                </td>
              </tr>
              <tr>
                <th>시행일<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input type="date" class="w_250" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>내용<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td textarea_full">
                    <textarea class="h_400"></textarea>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <button @click="addTermsOfUse" type="button" class="on">등록</button>
            <button @click="router.push('/admin/termsofuse/list')" type="button">취소</button>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import FilterTabs from "@/components/common/FilterTabs.vue";

const router = useRouter();

const statusOptions = [
  {label:'표시',value:true},
  {label:'미표시',value:false},
];

function onStatusSelected(statusValue){
  console.log(statusValue);
}

function addTermsOfUse(){
  if(!alert('등록되었습니다.')){
    router.push('/admin/termsofuse/list');
  }
}
</script>

<style scoped></style>
