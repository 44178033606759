<template>
  <router-view />
</template>

<script setup>
</script>
<style>
.none{
  display: none;
}

.cancel{
  cursor: pointer;
}
.flex{
  display: flex;
}
.column{
  flex-direction: column;
}
.align-center{
  align-items: center;
}
.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>