import {lockers} from "@/api";

// 구역별 락커번호 중복여부 체크 API
export function checkLockerNumber(areaId,lockerNum) {
    return lockers.get(`/locker-num`, {
        params: {areaId,lockerNum}
    });
}

// 시리얼번호 중복여부 체크API
export function checkSerialNumber(serialNumber){
    return lockers.get('/serial-number',{params:{serialNumber}});
}

// 락커 등록 API
export function registerLocker(lockerInfo){
    return lockers.post('',lockerInfo);
}

// 락커 페이징 조회
export function fetchLockers(companyId,areaId,searchInfo,pagination){
    return lockers.get('',{
        params:{
            companyId,areaId,
            batteryStatus: searchInfo.batteryStatus,
            isActive: searchInfo.isActive,
            searchKey: searchInfo.searchKey,
            searchValue: searchInfo.searchValue,
            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
        }
    });
}

// 락커 삭제 (lockerIds는 문자열. ','로 구분하여 여러개 삭제 가능 ex)1,2,3 or 5 )
export function removeLockers(lockerIds){
    return lockers.delete('',{
        params:{ids:lockerIds}
    });
}

export function modifyLockerActive(id,isActive){
    return lockers.patch(`/${id}/active`,{isActive});
}

// 락커 id로 락커 정보 조회
export function fetchLocker(lockerIds){
    return lockers.get(`/${lockerIds}`);
}

// 락커 수정 API
export function modifyLocker(lockerIds, lockerInfo){
    return lockers.patch(`/${lockerIds}`, lockerInfo);
}
