<template>
  <select class="w_200" v-model="companyId" :disabled="isDisabled">
    <option v-if="isViewMode" :value="null">전체</option>
    <option v-else disabled :value="null">업체를 선택하세요</option>
    <option v-for="company in companies" :value="company.companyId">
      {{ company.companyName }}
    </option>
  </select>
</template>

<script setup>

import {onMounted, ref} from "vue";
import {fetchMyCompany} from "@/api/company";

const companyId = defineModel('companyId');
const companies = ref([]);

const props = defineProps({
  isViewMode: {
    default: true,
  },
  isDisabled:{
    default: false
  }
});

onMounted(async () => {
  const {data} = await fetchMyCompany();
  companies.value = data;
});


</script>

<style scoped>

</style>