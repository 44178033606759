<template>
  <div id="Modal" class="d_pop">
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">반려하기</div>
        <a class="cancel" @click.prevent="emit('cancel')">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search">
          <table>
            <colgroup>
              <col style="width: 25%"/>
              <col/>
            </colgroup>
            <tbody>
            <tr>
              <th>사유입력</th>
              <td>
                <textarea class="w_97p h_200" placeholder="반려 사유를 입력하세요"
                          maxlength="20"
                          v-model="rejectReason"></textarea>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="d_pop_btn">
        <button type="button" class="on" @click="emit('reject',rejectReason)">완료</button>
        <button @click="emit('cancel')" type="button">취소</button>
      </div>
    </div>
  </div>
</template>

<script setup>

import {ref} from "vue";

const storeUserId = defineModel('storeUserId');
const rejectReason = ref('');

const emit = defineEmits(['cancel','reject']);

</script>

<style scoped>

</style>
