<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">락커 상세보기</div>
        <div class="description">락커 등록정보를 조회하고 수정합니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info" v-if="isDataLoaded">
            <LockerInfo :isEdit="true"/>
          </div>
          <div class="btn_bottom_a">
            <a @click.prevent="editLocker" href="###" class="on">저장</a>
            <a @click.prevent="deleteLocker" href="###">삭제</a>
            <a @click.prevent="listLocker" href="###">목록</a>
            <!-- <router-link to="/locker/admin/list">목록</router-link> -->
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onBeforeRouteLeave, useRoute, useRouter} from "vue-router";
import {onMounted, ref} from "vue";
import LockerInfo from "@/components/locker/LockerInfo.vue";
import {useLockerStore} from "@/store/locker";
import {storeToRefs} from "pinia";
import {modifyLocker, removeLockers} from "@/api/locker/locker";

const router = useRouter();
const route = useRoute();
const lockerStore = useLockerStore();

// const lockerNumbValid = ref({
//   msg: '',
//   valid: false,
// });
// const serialNumValid = ref({
//   msg: '',
//   valid: false,
// });

// 락커수정 초기상태를 저장할 변수
const initialState = ref({});
const isEdited = ref(false);
const isDataLoaded = ref(false);

const {companyId, areaId, tabletId, lockerNum, serialNumber, pinNumber, lockerNumbValid, serialNumValid} = storeToRefs(lockerStore);

onMounted(async () => {
  // 상태 깊은 복사
  await lockerStore.fetchLockerData(route.params.lockerId);
  initialState.value = JSON.parse(JSON.stringify(lockerStore.$state));
  isDataLoaded.value = true;
});

// function editLocker(){
//   if(!alert('저장이 완료되었습니다.')){
//     // 락커정보 수정...
//     router.push('/locker/admin/list');
//   }
// }
// function deleteLocker(){
//   if(confirm('락커를 삭제하시겠습니까?')){
//     // 락커삭제...
//     alert('삭제되었습니다.');
//     router.push('/locker/admin/list');
//   }
// }

function checkRequired() {
  return !!(companyId.value && areaId.value && tabletId.value &&
      lockerNumbValid.value.valid && serialNumValid.value.valid && pinNumber.value);
}

function isStateChanged() {
  return !!(companyId.value != initialState._rawValue.companyId || areaId.value != initialState._rawValue.areaId || tabletId.value != initialState._rawValue.tabletId ||
      lockerNum.value != initialState._rawValue.lockerNum || serialNumber.value != initialState._rawValue.serialNumber || pinNumber.value != initialState._rawValue.pinNumber);
}

async function editLocker() {
  if (!checkRequired()) {
    alert('락커정보를 모두 입력해 주세요');
    return;
  }

  try {
    const {status} = await modifyLocker(route.params.lockerId, {
      companyId: companyId.value,
      areaId: areaId.value,
      tabletId: tabletId.value,
      lockerNum: Number(lockerNum.value),
      serialNumber: serialNumber.value,
      pinNumber: pinNumber.value,
    });
    if (status === 200) {
      alert('저장이 완료되었습니다.')
      await router.push('/locker/admin/list');
    }
  } catch (e) {
    console.error(e);
  }
}

async function deleteLocker() {
  if (!confirm('삭제하시겠습니까?')) return;

  try {
    const resp = await removeLockers(route.params.lockerId);
    if (resp.status === 204) {
      alert('삭제되었습니다.');
      await router.push('/locker/admin/list');
    }
  } catch (error) {
    console.error(error);
  }
}

function listLocker(){
  if (isStateChanged()) {
    if (!confirm('입력 중인 내용이 사라집니다.\n계속 하시겠습니까?')) return;
  }
  router.push('/locker/admin/list');
}
</script>

<style scoped></style>
