import {companies} from "@/api/index";

// 업체 등록 API
export function createCompany(companyInfo) {
    // return companys.post('/companys',companyInfo);
    return companies.post('', companyInfo);
}

// 업체명 중복검사 API
export function checkCompanyName(companyName) {
    return companies.get(`/check-name`, {
        params: {
            name: companyName
        }
    });
}

// 사업자등록번호 중복검사 API
export function checkRegCode(companyRegCode) {
    return companies.get(`/check-reg-code`, {
        params: {
            regCode: companyRegCode
        }
    });
}

// 업체 활성 플래그 수정 API
export function modifyCompanyActive(companyId,activeFlag){
    return companies.patch(`/${companyId}/active`,{
        isActive:activeFlag
    })
}

// 업체 정보 수정 API
export function modifyCompany(companyId,companyInfo){
    return companies.patch(`${companyId}`,companyInfo);
}

// 업체 정보 목록(페이징) API
export function fetchCompanys(searchInfo, orderInfo, pagination) {
    return companies.get('', {
        params: {
            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
            filterKey: 'isActive',
            filterValue: searchInfo.filterValue,
            searchKey: searchInfo.searchKey,
            searchValue: searchInfo.searchValue,
            orderKey: orderInfo.orderKey,
            orderValue: orderInfo.orderValue,
        }
    });
}

//업체 삭제 (companyIds는 문자열. ','로 구분하여 여러개 삭제 가능 ex)1,2,3 or 5 )
export function removeCompanys(companyIds){
    return companies.delete('',{
        params:{ids:companyIds}
    });
}

// 업체 id로 업체 정보 조회
export function fetchCompany(companyId){
    return companies.get(`/${companyId}`);
}

// 나의 업체 조회 API(업체id,이름) (슈퍼관리자의 경우 활성화된 전체 업체 조회)
export function fetchMyCompany(){
    return companies.get(`/me`);
}

// 특정 업체의 기본 일일락커 제공시간 조회 API
export function fetchDefaultDailyDuration(companyId){
    return companies.get(`/${companyId}/daily-default-duration`);
}


