<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">회원승인관리</div>
        <div class="description">등록 요청한 회원을 조회하고 관리합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%"/>
                <col/>
              </colgroup>
              <tbody>
              <tr v-if="authStore.isSuperAdmin">
                <th>업체별</th>
                <td>
                  <div class="inner_td">
                    <CompanySelector v-model:companyId="companyId"/>
                  </div>
                </td>
              </tr>
              <tr>
                <th>기간별</th>
                <td>
                  <div class="inner_td m_date">
                    <select v-model="filterInfo.filterDateBy">
                      <option value="requestedAt">요청일시</option>
                      <option value="processedAt">처리일시</option>
                    </select>
                    <DatePicker class="w_300" v-model="filterInfo.dateRange"
                                :format="'yyyy-MM-dd'"
                                :enable-time-picker="false" auto-apply
                                placeholder="시작날짜-종료날짜"
                                locale="ko" range
                                @update:modelValue="fetchApprovalList"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>상태별</th>
                <td>
                  <FilterTabs :tabs="memberStatusOption"
                              v-model:select-tab="filterInfo.approvalStatus"
                              @tab-selected="onMemberStatusSelected"/>
                </td>
              </tr>
              <tr>
                <th>키워드검색</th>
                <td>
                  <div class="inner_td">
                    <select v-model="searchInfo.searchKey">
                      <option value="storeUserName">이름</option>
                      <option value="phoneNumber">휴대폰번호</option>
                    </select
                    ><input
                      type="text"
                      class="pop_text"
                      placeholder="검색어를 입력하세요"
                      v-model="searchInfo.searchValue"
                      @keyup.enter="fetchApprovalList"
                  />
                    <button @click="fetchApprovalList" type="button">검색</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="pagination.totalCount" unit="명"
                                 v-model:searchResultCount="pagination.pageSize"
                                 @resultCount="setPageSize"/>
            <button type="button" @click="approveUsers(getSelectedIds())">승인</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 6%"/>
                  <col style="width: 5%"/>
                  <col v-if="authStore.isSuperAdmin"/>
                  <col style="width: 9%"/>
                  <col style="width: 12%"/>
                  <col style="width: 11%"/>
                  <col style="width: 11%"/>
                  <col style="width: 9%"/>
                  <col style="width: 18%"/>
                </colgroup>
                <thead class="no_mobile">
                <tr>
                  <th>
                    <input type="checkbox" v-model="isAllSelected"/>
                  </th>
                  <template v-for="sortField in sortFieldsState" :key="sortField.key">
                    <th v-if="!sortField.super || authStore.isSuperAdmin">
                      <TableHeaderItem :headData="sortField" @sort="setOrderBy"/>
                    </th>
                  </template>
                </tr>
                </thead>
                <tbody>
                <template v-if="approvalList.length !== 0">
                  <ApprovalListItem v-for="approvalInfo in approvalList"
                                    :key="approvalInfo.storeUserId"
                                    :approvalInfo="approvalInfo"
                                    @rejectModalOn="writeModalOn"
                                    @viewModalOn="viewModalOn"
                                    @approve="approveUsers"/>
                </template>
                <tr class="no_data" v-else>
                  <td :colspan="authStore.isSuperAdmin?9:8">데이터가 없습니다</td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <a @click.prevent="approveUsers(getSelectedIds())" >승인</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <Pagination v-model:pageInfo="pagination"/>
          </div>
        </article>
      </div>
    </div>
  </div>
  <!-- Modal: 반려하기 -->
  <RejectWriteModal v-if="writeRejectModal" v-model:storeUserId="selectedUserId"
                    @cancel="writeRejectModal = false"
                    @reject="rejectSubmit"
  />
  <!-- //Modal: 반려하기 -->

  <!-- Modal: 반려사유조회 -->
  <RejectViewModal v-if="viewRejectModal" v-model:storeUserId="selectedUserId"
                   @cancel="viewRejectModal = false"/>
  <!-- //Modal: 반려사유조회 -->
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import FilterTabs from "@/components/common/FilterTabs.vue";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";
import {memberApprovalListSortFields} from "@/util/sortFields";
import TableHeaderItem from "@/components/common/TableHeaderItem.vue";
import {approveReject, fetchStoreUserRequests} from "@/api/storeUserRequests";
import {useAuthStore} from "@/store/auth";
import CompanySelector from "@/components/common/CompanySelector.vue";
import {fetchMyCompany} from "@/api/company";
import ApprovalListItem from "@/components/member/ApprovalListItem.vue";
import {useSelectAll} from "@/composables/selectAll";
import {useOrder} from "@/composables/orderBy";
import Pagination from "@/components/common/Pagination.vue";
import {approveStoreUsers} from "@/api/storeUserRequests";
import RejectWriteModal from "@/components/member/RejectWriteModal.vue";
import RejectViewModal from "@/components/member/RejectViewModal.vue";
import {memberStatusOption} from "@/util/filterFields";

const viewRejectModal = ref(false);
const writeRejectModal = ref(false);
const authStore = useAuthStore();

const companyId = ref(null);
const approvalList = ref([]);
const {isAllSelected,getSelectedIds} = useSelectAll(approvalList,'storeUserId');
// 모달창에 전달할 유저의 id
const selectedUserId = ref(null);

const {
  getOrderBy,
  setOrderBy,
  resetOrderBy,
  sortFieldsState
} = useOrder(memberApprovalListSortFields, fetchApprovalList);


const pagination = ref({
  pageNumber: 1,
  pageSize: 5,
  totalCount: 0
});

const filterInfo = ref({
  approvalStatus: null,
  filterDateBy: 'requestedAt',
  dateRange: [],
})

const searchInfo = ref({
  filterValue: null,
  searchKey: 'storeUserName',
  searchValue: null,
})



onMounted(async () => {
  try {
    if (!authStore.isSuperAdmin) { // 일반 업체일 경우 자신의 업체id가져오기
      companyId.value = authStore.companyId;
    }
    await fetchApprovalList();
  } catch (e) {
    console.error(e);
  }
});

watch(companyId, fetchApprovalList);

async function fetchApprovalList() {
  try {
    const {data} = await fetchStoreUserRequests(companyId.value, {
              searchKey: searchInfo.value.searchKey || null,
              searchValue: searchInfo.value.searchValue || null
            }, {
              approvalStatus: filterInfo.value.approvalStatus,
              filterDateBy: filterInfo.value.filterDateBy,
              dateRange: filterInfo.value.dateRange||[],
            },
            {
              orderKey: getOrderBy()?.key,
              orderValue: getOrderBy()?.direction
            },
            {
              pageNumber: pagination.value.pageNumber,
              pageSize:
              pagination.value.pageSize,
            }
        )
    ;
    console.log(data);

    pagination.value.pageNumber = data.pageNumber;
    pagination.value.pageSize = data.pageSize;
    pagination.value.totalCount = data.totalCount;

    approvalList.value = data.storeUserRequestMetaDataList;
    approvalList.value.forEach(approval => approval.isSelected = false);

  } catch
      (e) {
    console.error(e);
  }
}

// 페이지네이션 버튼 클릭시 호출
watch(() => pagination.value.pageNumber, (newPage, oldPage) => {
  if (newPage !== oldPage) {
    fetchApprovalList();
  }
}, {deep: true});


function onMemberStatusSelected() {
  pagination.value.pageNumber = 1;
  resetOrderBy();
  fetchApprovalList();
}

function setPageSize() {
  resetOrderBy();
  pagination.value.pageNumber = 1;
}

async function approveUsers(storeUserIds){
  console.log(storeUserIds);
  if(storeUserIds.length === 0){
    alert('선택된 항목이 없습니다.');
    return;
  }
  if(!confirm('승인하시겠습니까?')) return ;
  try{
    const {status} = await approveStoreUsers(storeUserIds);
    if(status === 200){
      alert('승인처리가 완료되었습니다.');
      await fetchApprovalList();
    }

  }catch(e){
    console.error(e);
  }
}


function viewModalOn(storeUserId) {
  selectedUserId.value = storeUserId;
  viewRejectModal.value = true;
}



function writeModalOn(storeUserId) {
  selectedUserId.value = storeUserId;
  writeRejectModal.value = true;
}

async function rejectSubmit(rejectReason){

  try{
    const {status} = await approveReject(selectedUserId.value,rejectReason);
    if(status === 200){
      alert('반려 처리되었습니다.');
      await fetchApprovalList();
      selectedUserId.value = null;
      writeRejectModal.value = false;
    }
  }catch(e){
    console.error(e);
  }
}



</script>

<style scoped>
.inner_td.m_date {
  flex-wrap: nowrap;
}

</style>
