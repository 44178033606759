<template>
  <tr class="excel_table">
    <td class="no_mobile">{{locker.__rowNum__}}</td>
    <td class="seat o3 o_br">
      <span class="only_mobile">락커번호 : </span>
      <input type="text" class="w_100p" v-model="locker.lockerNum" />
    </td>
    <td class="seat o3 o_br">
      <span class="only_mobile">시리얼넘버 : </span>
      <input type="text" class="w_100p" v-model="locker.serialNumber" />
    </td>
    <td class="seat o3 o_br">
      <span class="only_mobile">PIN번호 : </span>
      <input type="text" class="w_100p" v-model="locker.pinNumber" />
    </td>
    <td class="seat o3 o_br">
      <span class="only_mobile">중계기 : </span>
      <select class="w_100p" v-model="locker.tabletId">
<!--        <option disabled :value="null">중계기를 선택하세요</option>-->
        <option v-for="tablet in tablets" :value="tablet.tabletId">{{tablet.tabletName}}</option>
      </select>
    </td>
    <td class="seat o3 o_br">
      <span class="only_mobile">상태 : </span>
      <div v-if="locker.isValid" class="stat back_green"><span>가능</span></div>
      <div v-else class="stat back_red" ><span>불가능</span></div>
    </td>
    <td class="seat o3 o_br">
      <button @click="emits('remove',locker.__rowNum__)" type="button">X</button>
    </td>
  </tr>
</template>

<script setup>

import {watch} from "vue";
import {debounce} from "lodash";

const locker = defineModel('locker');
const props = defineProps(['tablets']);
const emits = defineEmits(['remove','validate']);


watch(locker,(newValue)=>{
  debouncedValid(newValue);
},{deep:true});

const debouncedValid = debounce(data=>{
  emits('validate',data.__rowNum__);
},200);


</script>

<style scoped>

</style>