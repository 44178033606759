import {defineStore} from "pinia";
import {ref} from "vue";
import {formatWithHyphen, splitByHyphen} from "@/util/formatUtils";
import {validateBusinessNum} from "@/util/regex";
import {checkCompanyName, checkRegCode, fetchCompany} from "@/api/company";
import {checkLoginId} from "@/api/managedUser";

export const useCompanyStore = defineStore('company', () => {
    // 기본정보
    const companyId = ref(0);
    const companyName = ref('');
    const ceoName = ref('');
    const companyRegCode = ref(['', '', '']);
    const phoneNumber = ref(['', '', '']);
    // 업체 주소
    const addressInfo = ref({
        zipCode: null,
        address: '',
        addressDetail: '',
    });
    // 담당자 정보
    const managerInfo = ref({
        managerName: '',
        managerPhoneNumber: ['010', '', ''],
        managerEmail: '',
    });
    // 계정 정보
    const managedUserName = ref('');
    const managedUserLoginId = ref('');
    const managedUserPassword = ref('');
    // 활성화 여부
    const isActive = ref(true);

    const valid = ref({
        validCompanyName: false,
        validCompanyRegCode: false,
        validLoginId: false,
    });
    // 비밀번호 초기화
    const isPasswordResetRequired = ref(false);

    async function isValidCompanyName(newCompanyName) {
        if ('' === newCompanyName) {
            valid.value.validCompanyName = false;
            return false;
        }
        try {
            const {data} = await checkCompanyName(newCompanyName);
            valid.value.validCompanyName = !data.exists;
            return !data.exists;
        } catch (error) {
            console.error(error);
            valid.value.validCompanyName = false;
            return false;
        }
    }

    async function isValidCompanyRegCode() {
        let regCode = formatWithHyphen(companyRegCode.value);
        if (!validateBusinessNum(regCode)) {
            valid.value.validCompanyRegCode = false;
            return false;
        }
        try {
            const {data} = await checkRegCode(regCode);
            valid.value.validCompanyRegCode = !data.exists;
            return !data.exists;
        } catch (error) {
            console.error(error);
            valid.value.validCompanyRegCode = false;
            return false;
        }
    }

    async function isValidLoginId() {
        if ('' === managedUserLoginId.value) {
            valid.value.validLoginId = false;
            return false;
        }
        try {
            const {data} = await checkLoginId(managedUserLoginId.value);
            valid.value.validLoginId = !data.exists;
            return !data.exists;
        } catch (error) {
            console.error(error);
            valid.value.validLoginId = false;
            return false;
        }
    }

    function checkRequiredFields() {
        return valid.value.validCompanyName &&
            ceoName.value !== '' &&
            valid.value.validCompanyRegCode &&
            phoneNumber.value.every(code => code !== '') &&
            addressInfo.value.address !== '' &&
            addressInfo.value.addressDetail !== '' &&
            addressInfo.value.zipCode !== '' &&
            managedUserName.value !== '' &&
            valid.value.validLoginId
            // &&
            // managedUserPassword.value !== '';
    }

    function getCreateData() {
        return {
            companyName: companyName.value,
            ceoName: ceoName.value,
            companyRegCode: formatWithHyphen(companyRegCode.value,'-'),
            phoneNumber: formatWithHyphen(phoneNumber.value),
            zipCode: addressInfo.value.zipCode,
            address: addressInfo.value.address,
            addressDetail: addressInfo.value.addressDetail,
            managerName: managerInfo.value.managerName,
            managerPhoneNumber: formatWithHyphen(managerInfo.value.managerPhoneNumber),
            managerEmail: managerInfo.value.managerEmail,
            managedUserName: managedUserName.value,
            managedUserLoginId: managedUserLoginId.value,
            managedUserPassword: managedUserPassword.value,
            isActive: isActive.value
        }
    }

    function getEditData() {
        return {
            companyName: companyName.value,
            ceoName: ceoName.value,
            companyRegCode: formatWithHyphen(companyRegCode.value),
            phoneNumber: formatWithHyphen(phoneNumber.value),
            zipCode: addressInfo.value.zipCode,
            address: addressInfo.value.address,
            addressDetail: addressInfo.value.addressDetail,
            managerName: managerInfo.value.managerName,
            managerPhoneNumber: formatWithHyphen(managerInfo.value.managerPhoneNumber),
            managerEmail: managerInfo.value.managerEmail,
            managedUserName: managedUserName.value,
            managedUserLoginId: managedUserLoginId.value,
            isPasswordResetRequired: isPasswordResetRequired.value,
        }
    }

    function $reset() {
        companyId.value = 0;
        companyName.value = '';
        ceoName.value = '';
        companyRegCode.value = ['', '', ''];
        phoneNumber.value = ['', '', ''];
        addressInfo.value = {
            zipCode: '',
            address: '',
            addressDetail: '',
        };
        managerInfo.value = {
            managerName: '',
            managerPhoneNumber: ['010', '', ''],
            managerEmail: '',
        };
        managedUserName.value = '';
        managedUserLoginId.value = '';
        managedUserPassword.value = '';

        valid.value.validCompanyName = false;
        valid.value.validCompanyRegCode = false;
        valid.value.validLoginId = false;
        isPasswordResetRequired.value = false;
    }

    async function fetchCompanyData(id) {
        try {
            const {data} = await fetchCompany((id));
            console.log(data);
            companyId.value = data.companyId;
            companyName.value = data.companyName;
            ceoName.value = data.ceoName;
            companyRegCode.value = splitByHyphen(data.companyRegCode);
            phoneNumber.value = splitByHyphen(data.companyMainPhoneNumber);
            addressInfo.value.address = data.address;
            addressInfo.value.addressDetail = data.addressDetail;
            addressInfo.value.zipCode = data.zipCode;
            managerInfo.value.managerName = data.managerName;
            managerInfo.value.managerEmail = data.email;
            managerInfo.value.managerPhoneNumber = splitByHyphen(data.managerPhoneNumber);
            managedUserName.value = data.managedUserName;
            managedUserLoginId.value = data.loginId;
            managedUserPassword.value = data.managedUserPassword;
            isActive.value = data.isActive;
            valid.value.validCompanyName = true;
            valid.value.validLoginId = true;
            valid.value.validCompanyRegCode = true;
        } catch (error) {
            console.error(error);
        }
    }

    // 초기값과 현재 상태를 비교하여 변화가 있는지 확인하는 함수
    function isStateChanged(initialStates){
        return JSON.stringify(this.$state) !== JSON.stringify(initialStates);
    }

    return {
        companyId,
        companyName, ceoName, companyRegCode,
        phoneNumber,
        managerInfo,
        managedUserName, managedUserLoginId,
        managedUserPassword, isActive,
        addressInfo,
        valid, isPasswordResetRequired,
        getCreateData,
        getEditData,
        checkRequiredFields,
        $reset,
        isValidCompanyName,
        isValidCompanyRegCode,
        isValidLoginId,
        fetchCompanyData,
        isStateChanged,
    }
});