<template>
  <div class="tbl_search tbl_info">
    <div class="part_title">기본정보</div>
    <table>
      <colgroup>
        <col style="width: 18%" class="col_width26"/>
        <col/>
      </colgroup>
      <tr v-if="authStore.isSuperAdmin">
        <th>{{ isEdit ? '업체' : '업체선택' }}<span class="text_red">*</span></th>
        <td>
          <div class="inner_td">
            <select class="w_250" v-model="storeUser.companyId" :disabled="isEdit">
              <template v-if="isEdit">
                <option :value="storeUser.companyId">
                  {{ storeUser.companyName }}
                </option>
              </template>
              <template v-else>
                <option selected disabled :value="null">업체를 선택하세요</option>
                <option v-for="company in companys" :value="company.companyId">
                  {{ company.companyName }}
                </option>
              </template>
            </select>
          </div>
        </td>
      </tr>
      <tr>
        <th>이름<span class="text_red">*</span></th>
        <td>
          <input
              type="text"
              class="w_250"
              placeholder="이름을 입력하세요"
              v-model="storeUser.name"
          />
        </td>
      </tr>
      <tr>
        <th>휴대폰번호<span class="text_red">*</span></th>
        <td>
          <div class="inner_td p_number">
            <select v-model="storeUser.phoneNumber[0]">
              <option>010</option>
              <option>011</option>
              <option>016</option>
              <option>017</option>
              <option>018</option>
              <option>019</option>
            </select>
            -
            <input type="text" class="w_55" maxlength="4" v-model="storeUser.phoneNumber[1]"/>
            -
            <input type="text" class="w_55" maxlength="4" v-model="storeUser.phoneNumber[2]"/>
            <div class="validation" :class="storeUser.phoneValid?'text_green':'text_red'">
              {{ phoneMsg }}
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th>생년월일</th>
        <td>
          <div class="inner_td">
            <DatePicker class="w_250" v-model="storeUser.birthday"
                        :format="'yyyy-MM-dd'"
                        :enable-time-picker="false" auto-apply
                        placeholder="연도-월-일"
                        locale="ko"
            />
          </div>
        </td>
      </tr>
      <tr>
        <th>성별</th>
        <td>
          <FilterTabs :tabs="genderOption"
                      v-model:select-tab="storeUser.gender"/>
        </td>
      </tr>
      <tr>
        <th>메모</th>
        <td><textarea class="w_97p" v-model="storeUser.description"></textarea></td>
      </tr>
    </table>
  </div>

</template>

<script setup>
import FilterTabs from "@/components/common/FilterTabs.vue";
import {useAuthStore} from "@/store/auth";
import {ref, watch} from "vue";
import {validatePhoneNum} from "@/util/regex";
import {checkStoreUserPhoneNumber} from "@/api/storeUser";
import {formatWithHyphen} from "@/util/formatUtils";

const authStore = useAuthStore();

const props = defineProps({
  isEdit: {
    type: Boolean, default: false, required: false
  },
  companys: {
    type: Array, default: []
  }
});

const storeUser = defineModel('storeUser');
const phoneMsg = ref('');
const initPhone = [...storeUser.value.phoneNumber];

const genderOption = [
  {label: '남성', value: 'male'},
  {label: '여성', value: 'female'},
];

watch(() => storeUser.value.companyId,
    async () => await checkDuplicatedPhone()
);

watch(() => storeUser.value.phoneNumber, async (newNumber) => {
      const companyId = storeUser.value.companyId;
      if (companyId === null) {
        alert('업체를 먼저 선택해주세요');
        storeUser.value.phoneValid = false;
        return;
      }
      if (props.isEdit && isPhoneNumberSame()) {
        storeUser.value.phoneValid = true;
        phoneMsg.value = '';
        return;
      }

      if (!validatePhoneNum(newNumber)) {
        phoneMsg.value = '휴대폰 번호가 정확한 지 확인해 주세요';
        storeUser.value.phoneValid = false;
        return;
      }
      await checkDuplicatedPhone();
    },
    {deep: true}
);

async function checkDuplicatedPhone() {
  if (!validatePhoneNum(storeUser.value.phoneNumber)) {
    phoneMsg.value = '휴대폰 번호가 정확한 지 확인해 주세요';
    storeUser.value.phoneValid = false;
    return;
  }
  try {
    const {data} = await checkStoreUserPhoneNumber(storeUser.value.companyId,
        formatWithHyphen(storeUser.value.phoneNumber));
    if (data.isAvailable) {
      phoneMsg.value = '등록 가능한 휴대폰번호 입니다.';
      storeUser.value.phoneValid = true;
    } else {
      storeUser.value.phoneValid = false;
      phoneMsg.value = data.reason === 'existsRegisteredPhoneNumber' ?
          '이미 등록된 휴대폰번호입니다.' : '이미 등록 요청된 휴대폰번호입니다. 회원 승인관리에서 승인을 진행해주세요';
    }
  } catch (e) {
    console.error(e);
  }
}

function isPhoneNumberSame() {
  return JSON.stringify(initPhone) === JSON.stringify(storeUser.value.phoneNumber);
}

</script>

<style scoped></style>