import {areas} from "@/api/index";

// 특정 회사의 구역 리스트 조회 API
export function fetchAreaList(companyId){
    return areas.get('/all',{params:{companyId}});
}

export function fetchAreas(companyId, searchInfo, pagination, orderInfo){
    return areas.get('', {
        params:{
            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
            companyId,
            searchKey: searchInfo.searchKey,
            searchValue: searchInfo.searchValue,
            orderKey: orderInfo.orderKey,
            orderValue: orderInfo.orderValue,
        }
    });
}

export function checkAreaName(companyId, areaName){
    return areas.get('/check-name', {params:{companyId, areaName}})
}

export function registerLockerArea(areaInfo){
    return areas.post('', areaInfo);
}

// 락커 삭제 (areaIds는 문자열. ','로 구분하여 여러개 삭제 가능 ex)1,2,3 or 5 )
export function removeLockerAreas(areaIds){
    return areas.delete('',{
        params:{ids:areaIds}
    });
}

export function fetchArea(areaId){
    return areas.get(`/${areaId}`);
}

export function modifyLockerArea(areaId, areaInfo){
    return areas.patch(`/${areaId}`, areaInfo);
}