import {onUnmounted, ref} from 'vue';

export function useOrder(sortFields, fetchInfo) {
    const sortFieldsState = ref(sortFields);

    function getOrderBy() {
        return sortFieldsState.value.find(field => field.direction !== null);
    }

    function setOrderBy({key, direction}) {
        sortFieldsState.value.forEach(field => {
            if (field.key === key) {
                field.direction = direction;
            } else {
                field.direction = null;
            }
        });
        fetchInfo();
    }

    function resetOrderBy() {
        sortFieldsState.value.forEach(field => field.direction = null);
    }

    onUnmounted(()=>{
        sortFieldsState.value = null;
    })

    return {
        sortFieldsState,
        getOrderBy,
        setOrderBy,
        resetOrderBy,
    };
}
