<template>
  <div id="Modal" class="d_pop" >
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">반려사유조회</div>
        <a @click.prevent="viewModalOff" >⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search">
          <table>
            <colgroup>
              <col style="width: 25%"/>
              <col/>
            </colgroup>
            <tbody>
            <tr>
              <th>반려사유</th>
              <td>{{reason}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="d_pop_btn">
        <button @click="viewModalOff" type="button" class="on">닫기</button>
      </div>
    </div>
  </div>
</template>

<script setup>


import {onMounted, ref} from "vue";
import {fetchRejectionReason} from "@/api/storeUserRequests";

const storeUserId = defineModel('storeUserId');
const reason = ref('');

const emit = defineEmits(['cancel','reject']);

onMounted(async ()=>{

  try{
    const {data} = await fetchRejectionReason(storeUserId.value);
    reason.value = data.rejectionReason;
  }catch (e){
    console.error(e);
  }
});


function viewModalOff(){
  emit('cancel');
}


</script>

<style scoped>
a{
  cursor: pointer;
}
</style>