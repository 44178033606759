import {storeUsers} from "@/api/index";
import {formatWithHyphen} from "@/util/formatUtils";

// 업체 유저 휴대폰번호 등록 가능 영부 체크 API
export function checkStoreUserPhoneNumber(companyId, phoneNumber) {
    return storeUsers.get('/check-phone-number', {
        params: {
            phoneNumber,
            companyId,
        }
    });
}

// 업체 유저 등록 API
export function registerStoreUser(storeUser) {
    return storeUsers.post('', {
        companyId: storeUser.companyId,
        name: storeUser.name,
        phoneNumber: formatWithHyphen(storeUser.phoneNumber),
        birthday: storeUser.birthday||null,
        gender: storeUser.gender,
        description: storeUser.description,
    });
}

// 업체 유저 페이징 API
export function fetchStoreUsers(companyId, searchInfo, orderInfo,pagination) {
    return storeUsers.get('', {
        params: {
            companyId,
            accountLinkStatus: searchInfo.filterValue,
            searchKey: searchInfo.searchKey,
            searchValue: searchInfo.searchValue,
            orderKey: orderInfo.orderKey,
            orderValue: orderInfo.orderValue,
            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
        }
    });
}

// 업체유저 상세 조회 API
export function fetchStoreUser(storeUserId){
    return storeUsers.get(`/${storeUserId}`);
}

// 업체유저 정보 수정
export function modifyStoreUser(storeUserInfo){
    return storeUsers.patch(`/${storeUserInfo.storeUserId}`,{
        companyId: storeUserInfo.companyId,
        name: storeUserInfo.name,
        phoneNumber: formatWithHyphen(storeUserInfo.phoneNumber),
        birthday: storeUserInfo.birthday||null,
        gender: storeUserInfo.gender,
        description: storeUserInfo.description || null,
    });
}

// 업체유저 검색 API
export function fetchSearchedStoreUser(companyId,searchValue){
    return storeUsers.get('/search',{
        params:{companyId,searchValue}
    });
}






