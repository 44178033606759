<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">이용약관 상세보기</div>
        <div class="description">이용약관을 조회 및 수정합니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>표시상태<span class="text_red">*</span></th>
                <td>
                  <FilterTabs :tabs="statusOptions" @tab-selected="onStatusSelected"/>
                </td>
              </tr>
              <tr>
                <th>시행일<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input type="date" class="w_250" value="2024-07-17" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>내용<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td textarea_full">
                    <textarea class="h_400">
내용내용내용내용내용내용내용내용내용내용내용내용</textarea
                    >
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <button @click="editTermsOfUse" type="button" class="on">저장</button>
            <button @click="deleteTermsOfUse" type="button">삭제</button>
            <button @click="router.push('/admin/termsofuse/list')" type="button">목록</button>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import FilterTabs from "@/components/common/FilterTabs.vue";

const router = useRouter();

const statusOptions = [
  {label:'표시',value:true},
  {label:'미표시',value:false},
];

function onStatusSelected(statusValue){
  console.log(statusValue);
}

function editTermsOfUse(){
  if(!alert('저장이 완료도었습니다')){
    router.push('/admin/termsofuse/list');
  }
}
function deleteTermsOfUse() {
  if (confirm('삭제하시겠습니까?')) {
    // 락커 구역 삭제...
    alert('삭제되었습니다.');
    router.push('/admin/termsofuse/list');
  }
}
</script>

<style scoped></style>
