<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_54.png" alt="" />업체관리
    </div>
    <div class="left_body">
      <ul class="menu">
        <li  v-for="page in pages">
          <router-link
            :to="page.link"
            :class="{ on: checkSideActive(page.link)}"
          >
            {{page.label}}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {checkSideActive} from "@/components/Layout/common/layoutCommon";
import {ref} from "vue";

const pages = ref([
  {label:'업체목록',link:'/company/list'},
  {label:'업체등록',link:'/company/add'},
]);
</script>
