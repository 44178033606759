<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_74.png" alt="" />사용자관리
    </div>
    <div class="left_body">
      <ul class="menu">
        <li  v-for="page in pages">
          <router-link
              :to="page.link"
              :class="{ on: checkSideActive(page.link)}"
          >
            {{page.label}}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {checkSideActive} from "@/components/Layout/common/layoutCommon";
import {ref} from "vue";

const pages = ref([
  {label:'사용자 목록',link:'/user/list'},
  {label:'사용자 상세보기',link:'/user/view'},
  {label:'탈퇴한 사용자',link:'/user/withdraw/list'},
]);

</script>

<style scoped></style>
