<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">락커 배정이력</div>
        <div class="description">락커 배정, 연장, 회수 이력을 조회합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>업체별</th>
                  <td>
                    <div class="inner_td">
                      <select class="w_200">
                        <option>전체</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>기간별</th>
                  <td>
                    <div class="inner_td m_date2">
                      <select>
                        <option>배정일시</option>
                      </select>
                      <input type="date" /> ~ <input type="date" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>구분별</th>
                  <td>
                    <FilterTabs :tabs="divisionOptions" @tab-selected="onDivisionSelected"/>
                  </td>
                </tr>
                <tr>
                  <th>배정유형별</th>
                  <td>
                    <FilterTabs :tabs="assigmentOptions" @tab-selected="onAssigmentSelected"/>
                  </td>
                </tr>
                <tr>
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <select>
                        <option>전체</option>
                        <option>구역명</option>
                        <option>락커번호</option>
                        <option>이름</option></select
                      ><input
                        type="text"
                        class="pop_text"
                        placeholder="검색어를 입력하세요"
                      /><button type="button">검색</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="42" unit="건"
                                 @resultCount="console.log"/>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 5%" />
                  <col style="width: 4%" />
                  <col />
                  <col style="width: 13%" />
                  <col style="width: 9%" />
                  <col style="width: 10%" />
                  <col style="width: 10%" />
                  <col style="width: 15%" />
                  <col style="width: 11%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input type="checkbox" />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">상호명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">처리일시</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">구분</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">이름</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">락커번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">이용기간</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">유형</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="o1">
                      <input type="checkbox" />
                    </td>
                    <td class="no_mobile">3</td>
                    <td class="seat o6r">
                      <span class="only_mobile">상호명 : </span>
                      <span>주식회사마이즈</span>
                    </td>
                    <td class="num seat o3">
                      <span class="only_mobile">처리일시 : </span>
                      <span>2024.07.01 15:00</span>
                    </td>
                    <td class="seat o4r">
                      <span class="only_mobile">구분 : </span>
                      <span class="text_blue bold">연장</span><br /><span
                        class="text_grey"
                        >(관리자)</span
                      >
                    </td>
                    <td class="seat o5 detail_page_a">
                      <span class="only_mobile">이름 : </span>
                      <div class="center_td">
                        <router-link to="/member/view" class="center_td">
                          <p class="text_bold long_p">서다혜</p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                    <td class="o_title o2">
                      <span class="only_mobile">락커번호 : </span>
                      <span>[1F] 3</span>
                    </td>
                    <td class="num o7 o_br">
                      <span class="only_mobile">이용기간 : </span>
                      <span>2024.07.01 15:00</span><br />
                      <span class="only_mobile"> ~</span>
                      <span>2024.07.01 18:00</span>
                    </td>
                    <td class="o1 o_br">일일락커</td>
                  </tr>
                  <tr>
                    <td class="o1">
                      <input type="checkbox" />
                    </td>
                    <td class="no_mobile">3</td>
                    <td class="seat o6r">
                      <span class="only_mobile">상호명 : </span>
                      <span>주식회사마이즈</span>
                    </td>
                    <td class="num seat o3">
                      <span class="only_mobile">처리일시 : </span>
                      <span>2024.07.01 15:00</span>
                    </td>
                    <td class="seat o4r">
                      <span class="only_mobile">구분 : </span>
                      <span class="text_green bold">배정</span>
                    </td>
                    <td class="seat o5 detail_page_a">
                      <span class="only_mobile">이름 : </span>
                      <div class="center_td">
                        <router-link to="/member/view" class="center_td">
                          <p class="text_bold long_p">서다혜</p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                    <td class="o_title o2">
                      <span class="only_mobile">락커번호 : </span>
                      <span>[1F] 3</span>
                    </td>
                    <td class="num o7 o_br">
                      <span class="only_mobile">이용기간 : </span>
                      <span>2024.07.01 15:00</span><br />
                      <span class="only_mobile"> ~</span>
                      <span>2024.07.01 18:00</span>
                    </td>
                    <td class="o1 o_br">일일락커</td>
                  </tr>
                  <tr>
                    <td class="o1">
                      <input type="checkbox" />
                    </td>
                    <td class="no_mobile">3</td>
                    <td class="seat o6r">
                      <span class="only_mobile">상호명 : </span>
                      <span>주식회사마이즈</span>
                    </td>
                    <td class="num seat o3">
                      <span class="only_mobile">처리일시 : </span>
                      <span>2024.07.01 15:00</span>
                    </td>
                    <td class="seat o4r">
                      <span class="only_mobile">구분 : </span>
                      <span class="text_grey bold">반납</span>
                    </td>
                    <td class="seat o5 detail_page_a">
                      <span class="only_mobile">이름 : </span>
                      <div class="center_td">
                        <router-link to="/member/view" class="center_td">
                          <p class="text_bold long_p">서다혜</p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                    <td class="o_title o2">
                      <span class="only_mobile">락커번호 : </span>
                      <span>[1F] 3</span>
                    </td>
                    <td class="num o7 o_br">
                      <span class="only_mobile">이용기간 : </span>
                      <span>2024.07.01 15:00</span><br />
                      <span class="only_mobile"> ~</span>
                      <span>2024.07.01 18:00</span>
                    </td>
                    <td class="o1 o_br">개인락커</td>
                  </tr>
                </tbody>
                <tbody style="display: none">
                  <tr class="no_data">
                    <td colspan="8">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <a href="###">삭제</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a href="###"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a href="###" class="on">1</a>
              <a href="###">2</a>
              <a href="###">3</a>
              <a href="###"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a href="###"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import FilterTabs from "@/components/common/FilterTabs.vue";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";

const divisionOptions = [
  { label: '전체', value: 'all' },
  { label: '연장(관리자)', value: 'extendAdmin' },
  { label: '연장(회원)', value: 'extendMember' },
  { label: '강제회수', value: 'forcedWithdrawal' },
  { label: '반납', value: 'return' },
];

const assigmentOptions = [
  { label: '전체보기', value: 'all' },
  { label: '일일락커', value: 'daily' },
  { label: '개인락커', value: 'personal' },
];


function onDivisionSelected(tabValue){
  console.log(tabValue);
}
function onAssigmentSelected(tabValue){
  console.log(tabValue);
}

</script>

<style scoped></style>
