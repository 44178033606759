<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">사용자 상세보기</div>
        <div class="description">App 사용자 정보를 조회하고 수정합니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">계정정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>가입수단</th>
                <td>
                  <div class="inner_td">이메일</div>
                </td>
              </tr>
              <tr>
                <th>이메일<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input
                      type="text"
                      class="w_250"
                      placeholder="이메일을 입력하세요"
                      value="mize1234@gmail.com"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>비밀번호<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input
                      type="password"
                      class="w_250"
                      placeholder="비밀번호를 입력하세요"
                      value="12345678"
                    />
                    <div>
                      <label>
                        <input type="checkbox" />
                        비밀번호보이기</label
                      >
                    </div>
                    <div class="validation text_red" style="width: 100%">
                      8-16자의 영문+숫자+특수문자 조합으로 입력해 주세요.
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">가입정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tr>
                <th>이름</th>
                <td>
                  <div class="inner_td">
                    <input
                      type="text"
                      class="w_250"
                      placeholder="이름을 입력하세요"
                      value="홍길동"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>휴대폰번호<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td p_number">
                    <select>
                      <option>010</option>
                      <option>011</option>
                      <option>016</option>
                      <option>017</option>
                      <option>018</option>
                      <option>019</option>
                    </select>
                    -
                    <input
                      type="text"
                      class="w_55"
                      maxlength="4"
                      value="1234"
                    />
                    -
                    <input
                      type="text"
                      class="w_55"
                      maxlength="4"
                      value="5678"
                    />
                    <div class="validation text_red">
                      이미 등록된 휴대폰번호입니다.
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>생년월일</th>
                <td>
                  <div class="inner_td">
                    <input
                      type="date"
                      class="w_250"
                      placeholder="생년월일을 입력하세요"
                      value=""
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>성별</th>
                <td>
                  <FilterTabs :tabs="genderOption" @tab-selected="onGenderSelected" />
                </td>
              </tr>
              <tr>
                <th>간편비밀번호<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input
                      type="password"
                      class="w_250"
                      placeholder="간편비밀번호를 입력하세요"
                      value="1234"
                    />
                    <div>
                      <label>
                        <input type="checkbox" />
                        비밀번호보이기</label
                      >
                    </div>
                    <div class="validation text_red" style="width: 100%">
                      숫자 4자리를 입력해 주세요.
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a @click.prevent="editUser" href="#" class="on">수정</a>
            <a @click.prevent="deleteUser" href="#">삭제</a>
            <router-link to="/user/list">목록</router-link>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import FilterTabs from "@/components/common/FilterTabs.vue";

const router = useRouter();

const genderOption = [
  {label:'남성',value:'M'},
  {label:'여성',value:'F'},
]


function editUser(){
  if(!alert('사용자 정보 수정')){
    router.push('/user/list');
  }
}
function deleteUser(){
  if(confirm('해당 사용자를 삭제하시겠습니까?')){
    router.push('/user/list');
  }
}

function onGenderSelected(genderValue){
  console.log(genderValue);
}

</script>

<style scoped></style>
