<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_66.png" alt="" />락커관리
    </div>
    <div class="left_body">
      <ul class="menu">
        <li  v-for="page in pages">
          <router-link
              :to="page.link"
              :class="{ on: checkSideActive(page.link)}"
          >
            {{page.label}}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {checkSideActive} from "@/components/Layout/common/layoutCommon";

import {ref} from "vue";

const pages = ref([
  {label:'락커 사용현황',link:'/locker/use/usestatus'},
  {label:'락커 배정이력',link:'/locker/use/assignmenthistory'},
  {label:'락커 제어이력',link:'/locker/use/controlstatus'},
  {label:'락커 목록',link:'/locker/admin/list'},
  {label:'락커 등록',link:'/locker/admin/add'},
  // {label:'락커 상세보기',link:'/locker/admin/view'},
  {label:'락커 일괄등록',link:'/locker/admin/batchadd'},
  {label:'락커 구역 목록',link:'/locker/area/list'},
  {label:'락커 구역 등록',link:'/locker/area/add'},
  // {label:'락커 구역 상세보기',link:'/locker/area/view'},
  {label:'락커 중계기 목록',link:'/locker/relay/list'},
  {label:'락커 중계기 등록',link:'/locker/relay/add'},
  {label:'락커 중계기 상세보기',link:'/locker/relay/view'},
]);
</script>

<style scoped></style>
