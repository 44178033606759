import {defineStore} from "pinia";
import {ref} from "vue";
import {fetchArea} from "@/api/area";

export const useAreaStore = defineStore('area', () => {
    const companyId = ref(0);
    const companyName = ref('');
    const areaId = ref(0);
    const areaName = ref('');

    async function fetchAreaData(id) {
        try {
            const {data} = await fetchArea((id));
            console.log(data);

            companyId.value = data.companyId;
            companyName.value = data.companyName;
            areaId.value = data.areaId;
            areaName.value = data.areaName;
        } catch (error) {
            console.error(error);
        }
    }

    return {
        companyId,
        companyName,
        areaId,
        areaName,
        fetchAreaData,
    }
});