<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">탈퇴한 사용자</div>
        <div class="description">탈퇴한 사용자를 조회합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>탈퇴날짜</th>
                  <td>
                    <div class="inner_td">
                      <input type="date" /> ~
                      <input type="date" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>가입수단</th>
                  <td>
                    <FilterTabs :tabs="joinTypeOptions"
                                v-model:select-tab="joinType"
                                @tab-selected="onJoinTypeSelected"/>
                  </td>
                </tr>
                <tr>
                  <th>탈퇴유형</th>
                  <td>
                    <FilterTabs :tabs="withdrawTypeOptions"
                                v-model:select-tab="withdrawType"
                                @tab-selected="onWithdrawTypeSelected"/>
                  </td>
                </tr>
                <tr>
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <select>
                        <option>전체</option>
                        <option>휴대폰번호</option>
                        <option>이름</option>
                        <option>이메일</option>
                      </select>
                      <input
                        type="text"
                        class="pop_text"
                        placeholder="검색어를 입력하세요"
                      />
                      <button type="button">검색</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="42" unit="명"
                                 @resultCount="console.log"/>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 6%" />
                  <col style="width: 11%" />
                  <col style="width: 6%" />
                  <col style="width: 13%" />
                  <col />
                  <col style="width: 10%" />
                  <col style="width: 10%" />
                  <col style="width: 11%" />
                  <col style="width: 9%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input type="checkbox" />
                    </th>
                    <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">이메일</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">이름</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">휴대폰번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>

                    <th>
                      <a href="###">가입수단</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">탈퇴유형</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">탈퇴일</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>관리</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="o1"><input type="checkbox" /></td>
                    <td class="no_mobile num">2</td>
                    <td class="num phone o8 o_br">
                      <span class="only_mobile">이메일 : </span>
                      <span>admin6640@gamil.com</span>
                    </td>
                    <td class="seat o2 o_title">
                      <span>홍길동</span>
                    </td>
                    <td class="num phone o6">
                      <span class="only_mobile">휴대폰번호 : </span>
                      <span>010-5588-6655</span>
                    </td>

                    <td class="seat o4">
                      <span class="only_mobile">가입수단 : </span>
                      <span>휴대폰번호</span>
                    </td>
                    <td class="seat o5r">
                      <span class="only_mobile">탈퇴유형 : </span>
                      <span>휴대폰번호</span>
                    </td>
                    <td class="num phone o7r">
                      <span class="only_mobile">탈퇴일 : </span>
                      <span>2024-07-15</span>
                    </td>
                    <td class="num o_stat">
                      <div class="center_td">
                        <button type="button">삭제</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody style="display: none">
                  <tr class="no_data">
                    <td colspan="8">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <a href="###">삭제</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <div class="pageing">
              <a href="###"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a href="###" class="on">1</a>
              <a href="###">2</a>
              <a href="###">3</a>
              <a href="###"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a href="###"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import FilterTabs from "@/components/common/FilterTabs.vue";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";
import {ref} from "vue";

const joinTypeOptions = [
  { label: '전체보기', value: null },
  { label: '휴대폰번호', value: 'phone' },
  { label: '카카오톡', value: 'kakao' },
  { label: '네이버', value: 'naver' },
  { label: '구글', value: 'google' },
  { label: '애플', value: 'apple' },
];

const withdrawTypeOptions = [
  { label: '전체보기', value: null },
  { label: '본인탈퇴', value: 'self' },
  { label: '강제탈퇴', value: 'forcing' },
];

const joinType = ref(null);
const withdrawType = ref(null);

function onJoinTypeSelected(tabValue){
  console.log(tabValue);
}
function onWithdrawTypeSelected(tabValue){
  console.log(tabValue);
}

</script>

<style scoped></style>
