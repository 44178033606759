<template>
  <div class="part_title">계정생성</div>
  <table>
    <colgroup>
      <col style="width: 18%" class="col_width26" />
      <col />
    </colgroup>
    <tr>
      <th>이름<span class="text_red">*</span></th>
      <td>
        <input
          type="text"
          class="w_250"
          placeholder="이름을 입력하세요"
          v-model="managedUserName"
        />
      </td>
    </tr>
    <tr>
      <th>아이디<span class="text_red">*</span></th>
      <td>
        <div class="inner_td">
          <input
            type="text"
            class="w_250"
            placeholder="아이디를 입력하세요"
            v-model="managedUserLoginId"
          />
          <div
            class="validation"
            :class="
              valid.validLoginId && managedUserLoginId
                ? 'text_green'
                : 'text_red'
            "
          >
            {{ loginIdMsg }}
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <th>비밀번호<span class="text_red">*</span></th>
      <td>
        <div class="passoword_reset_box">
          <label>
            <input type="checkbox" v-model="isPasswordResetRequired"/>
            <span>비밀번호 초기화</span></label
          >
          <span class="accent">(qwer12#$)</span>
        </div>
      </td>
    </tr>
  </table>
</template>

<script setup>
import { useCompanyStore } from "@/store/company";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import PasswordInput from "@/components/common/PasswordInput.vue";
import { debounce } from "lodash";

const companyStore = useCompanyStore();
const { managedUserName, managedUserLoginId, isPasswordResetRequired, valid } =
  storeToRefs(companyStore);

const loginIdMsg = ref("");
const initialLoginId = managedUserLoginId.value;

watch(managedUserLoginId, (newLoginId) => {
  checkManagedUserLoginId(newLoginId);
});

const checkManagedUserLoginId = debounce(async (newLoginId) => {
  if(initialLoginId === newLoginId){
    loginIdMsg.value = "";
    companyStore.valid.validLoginId = true;
    return ;
  }

  if (!newLoginId) {
    loginIdMsg.value = "아이디를 입력해주세요.";
    companyStore.valid.validLoginId = false;
  } else if (!(await companyStore.isValidLoginId())) {
    loginIdMsg.value = "이미 등록된 아이디입니다.";
  } else {
    // api로 중복검사도 하기
    loginIdMsg.value = "등록가능한 아이디입니다.";
  }
}, 300);
</script>

<style scoped></style>
